import React, {cloneElement} from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    SimpleShowLayout,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import CommunitiesFilter from "./Component/CommunitiesFilter";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import ChipMembersField from "./Component/ChipMembersField";
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";
import DeepLinkField from "./Component/DeepLinkField";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import ActivateButton from "./Component/ActivateButton";
import DeactivateButton from "./Component/DeactivateButton";
import ExportCommunityButton from "./Component/ExportCommunityButton";
import {ADMIN, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import CreateButtonMod from "../../../App/Component/Redesign/Button/CreateButtonMod";
import UserField from "../../Users/Users/Component/UserField";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import communities from "../../../Pages/Communities/Communities";

export const CommunitiesList = ({permissions, ...props}) => (
    <ListMod {...props}
        filters={<CommunitiesFilter />}
        title="List of Communities"
        basePath={`/${communities.name}`}
        actions={<ListActions permissions={permissions} />}
    >
        <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            {HasPermissions([SUPPORT_ADMIN]) &&
                <UserField label="Leader" source="leaderId" sortable={false} />
            }
            <ChipMembersField label="Number of users" source="members" />
            <TextField label="Code" source="code" />
            <DeepLinkField label="Deep Link" />
            <VerticalToolbar {...props} >
                <ShowButton />
                <EditButtonMod permissions={[SUPPORT_ADMIN]} />
                <ActivateButton permissions={[SUPPORT_ADMIN]} />
                <DeactivateButton permissions={[SUPPORT_ADMIN]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <TextField addLabel={true} label="Key" source="key" />
        <ImageFieldMod  addLabel={true} source="image" elStyle={{image: {maxHeight: '200px', maxWidth: '200px'}}} />
    </SimpleShowLayout>
);

const ListActions = ({permissions, ...props}) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {resource, displayedFilters, filterValues, showFilter, basePath} = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <CreateButtonMod basePath={basePath} permissions={[SUPPORT_ADMIN]} />
            {filters && cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button'})}
            <ExportCommunityButton filterValues={filterValues} permissions={[ADMIN]} />
        </TopToolbar>
    );
};