import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    SimpleShowLayout,
    RichTextField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";
import NounsFilter from "./Component/NounsFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import CountChipField from "../../../App/Component/Redesign/Field/CountChipField";
import nouns from "../../../Pages/Archetypes/Nouns";

export const NounsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<NounsFilter />}
          title="List of Nouns"
          basePath={`/${nouns.name}`}
    >
        <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ImageFieldMod source="image" sortable={false} elStyle={{image: {maxHeight: '100px', maxWidth: '100px'}}} />
            <CountChipField label="Count users" source="count" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField label="Description" source="description" />
    </SimpleShowLayout>
);