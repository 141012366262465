import {Box, Card, Divider, Typography} from "@material-ui/core";
import {createElement} from "react";
import * as React from "react";
import { Link } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        minHeight: 52,
        maxHeight: 1200,
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    main: {
        position: 'relative',
        height: 58,
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: '#00a3e6',
            zIndex: 2,
        },
        '&::after': {
            content: `''`,
            position: 'absolute',
            top: 0,
            left: 0,
            width: 90,
            height: '100%',
            backgroundColor: '#cbebf9',
            zIndex: 1,
            borderRadius: '0 0 200% 0',
        },
    },
    cardContent: {
        zIndex: 2,
    },
    title: {},
}));

const CardWithIcon = props => {
    const { icon, title, subtitle, to, children } = props;
    const classes = useStyles(props);

    return (
        <Card className={classes.card}>
            <Link to={to}>
                <div className={classes.main}>
                    <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right" className={classes.cardContent}>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                        >
                            {title}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            <span>{`${subtitle}`}</span>
                        </Typography>
                    </Box>
                </div>
            </Link>
            {children && <Divider />}
            {children}
        </Card>
    );
};

export default CardWithIcon;