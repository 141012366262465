import React from 'react';
import {
    TextField,
    SelectField,
    Show,
    SimpleShowLayout,
    ReferenceField,
    FunctionField,
} from 'react-admin';
import {AdminRolesList} from "../../../App/Component/Constant/Roles";
import countries from "../../Countries/Countries";

export const AdminShow = ( {permissions, ...props} ) => (
    <Show title="Internal User View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Last Name" source="lastName" />
            <TextField label="Email" source="email" />
            <ReferenceField label="Country" source="countryId" reference={countries.name} link="show">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="Phone" render={record => record.phone ? `${record.phoneCode}${record.phone}` : ``} />
            <SelectField label="Role" source="role" choices={AdminRolesList} />
        </SimpleShowLayout>
    </Show>
);