import decodeJwt from "jwt-decode";
import {HasPermissions} from "../Component/Handlers/HasPermissions";
import {stringify} from 'qs';
import {ADMIN, CONTENT_MANAGER, SUPPORT_ADMIN, VIEWER} from "../Component/Constant/Roles";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const checkAuthEndpoint = apiUrl + '/admin/token/check';
const refreshTokenEndpoint = apiUrl + '/admin/token/refresh';

const inMemoryJWTManager = () => {
    let isRefreshing = null;
    let refreshTimeOutId;
    const delay = process.env.REACT_APP_TOKEN_TTL;
    const logoutEventName = 'ra-logout';

    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const setRefreshToken = () => {
        // Validity period of the token in seconds, minus 5 seconds
        refreshTimeOutId = delay && window.setTimeout(
            checkAuth,
            delay * 1000 - 5000
        );
    }

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    }
    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }

        return isRefreshing.then(() => {
            isRefreshing = null;

            return true;
        });
    }

    const checkAuth = () => {
        const headers = new Headers({});
        headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

        const request = new Request(checkAuthEndpoint, {
            method: 'GET',
            headers: headers
        });

        return fetch(request)
            .then((response) => {
                if (response.status === 200) {
                    setRefreshToken();
                    return true;
                }

                return getRefreshedToken();
            });
    }

    const getRefreshedToken = () => {
        const request = new Request(refreshTokenEndpoint, {
            method: 'POST',
            body: stringify({
                refreshToken: localStorage.getItem('refreshToken'),
            }),
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    clearToken();
                    global.console.log('Token renewal failure');
                    return { data: { token: null } };
                }

                return response.json();
            })
            .then(data => {
                if (data.status) {
                    const decodedToken = decodeJwt(data.data.token);

                    if (!HasPermissions([ADMIN, SUPPORT_ADMIN, CONTENT_MANAGER, VIEWER], decodedToken.roles[0])) {
                        throw new Error("You don`t have permission!");
                    }
                }

                return data;
            })
            .then(({ data }) => {
                if (data.token) {
                    setToken(data.token, data.refreshToken);
                    return true;
                }

                clearToken();
                return false;
            });

        return isRefreshing;
    }

    const decodeToken = () => {
        const token = getToken();
        return token ? decodeJwt(token) : null;
    }

    const hasPermissions = () => {
        const decodedToken = decodeToken();
        if (!decodedToken) {
            return false;
        }

        return HasPermissions([], decodedToken.roles[0]);
    }

    const getRoles = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.roles[0] : false;
    }

    const getId = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.id : false;
    }

    const getName = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.name : false;
    }

    const getEmail = () => {
        const decodedToken = decodeToken();
        return decodedToken ? decodedToken.email : false;
    }

    const isExpired = () => {
        const decodedToken = decodeToken();
        const currentTime = new Date().getTime() / 1000;

        return  currentTime > decodedToken.exp;
    }

    const getToken = () => localStorage.getItem('token');

    const setToken = (token, refreshToken) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        setRefreshToken();

        return true;
    };

    const clearToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        abordRefreshToken();
        localStorage.clear();

        localStorage.setItem(logoutEventName, Date.now());

        return true;
    }

    return {
        clearToken,
        getToken,
        decodeToken,
        getRoles,
        getId,
        getName,
        getEmail,
        setToken,
        hasPermissions,
        isExpired,
        abordRefreshToken,
        waitForTokenRefresh,
        getRefreshedToken,
        setRefreshToken
    }
};

export default inMemoryJWTManager();