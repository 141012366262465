import React from 'react';
import {
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin';
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import {AdminStatus} from "../constant/adminConst";

const AdminsFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name, Email" source="search" alwaysOn />
        <SelectInput label="Role" source="role" choices={AdminRolesList} />
        <SelectInput label="Status" source="status" choices={AdminStatus} />
    </Filter>
);

export default AdminsFilter;