import React from 'react';
import {FeedbackStatus} from "../constant/feedbackConst";
import StatusButton from "../../../../App/Component/Redesign/Button/StatusButton";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../../App/Component/Constant/Roles";
import feedbacks from '../../Feedbacks';

const ChangeStatusButton = ({permissions, ...props}) => {
    return <StatusButton {...props}
                         disabled={!HasPermissions([SUPPORT_ADMIN])}
                         addLabel={true}
                         label="Status"
                         resource={feedbacks.name}
                         options={FeedbackStatus}
                         successNotify='custom.feedbacks.status.update.success' />
};

export default ChangeStatusButton;