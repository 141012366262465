import React from 'react';
import {
    TextInput,
    Edit,
    SimpleForm,
    ImageInput,
    AutocompleteInput,
    ReferenceInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName} from "./validation/validation";
import PreviewImageField from "../../../App/Component/Redesign/Field/PreviewImageField";
import usersShort from "../../Users/UsersShort";

const transform = data => ({
    ...data,
    updateMethod: 'POST'
});

const optionRendererUser = record => {
    if (record.id) {
        return record.name ? `${record.name} #${record.id}` : `#${record.id}`;
    }

    return null;
}

export const CommunityEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic" transform={transform}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <TextInput disabled fullWidth label="Key" source="key" />
            <TextInput disabled fullWidth label="Code" source="code" />
            <ReferenceInput fullWidth
                            label="Leader"
                            source="leaderId"
                            reference={usersShort.name}
                            allowEmpty={true}
                            filterToQuery={searchText => ({ search: searchText })}>
                <AutocompleteInput optionText={optionRendererUser} />
            </ReferenceInput>
            <ImageInput source="image"
                        label="Image"
                        accept="image/png"
                        maxSize={5242880}
                        placeholder={
                            <p>
                                Drop a picture to upload, or click to select it.<br/>
                                Maximum file size for uploads is 5MB.<br/>
                                Available type(s) of files: png.
                            </p>
                        }>
                <PreviewImageField source="image" title="Image" />
            </ImageInput>
        </SimpleForm>
    </Edit>;
};