import ListMod from "../../../App/Component/Redesign/ListMod";
import sharingsBatches from "../../Sharings/Batches";
import {Datagrid, ReferenceArrayField, SimpleShowLayout, SingleFieldList, TextField} from "react-admin";
import React from "react";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import AdminField from "../../Users/Admins/Component/AdminField";
import BatchFilter from "./Component/BatchFilter";
import sharingsQuestion from "../Questions"
import {makeStyles} from "@material-ui/core/styles";
import ChipFieldMod from "./Component/ChipFieldMod";
import ChangeStatusButton from "./Component/ChangeStatusButton";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";

const useStyles = makeStyles({
    listColumn: {
        "& div[class*=\"RaSingleFieldList-root-\"]": {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    labelPosition: {

    },
});

export const BatchesList = ({permissions, ...props}) => {
    const classes = useStyles();
    return <ListMod {...props}
             filters={<BatchFilter/>}
             title="List of Batches"
             basePath={`/${sharingsBatches.name}`}
    >
        <Datagrid expand={<BatchShow className = {classes.listColumn}/>}>
            <TextField label="ID" source="id"/>
            <TextField label="Name" source="name" sortable={false}/>
            <ChangeStatusButton label="Status" permissions={[CONTENT_MANAGER]}/>
            <DateFieldMod addLabel={true} label="Publish Date" source="publishDate" showTime={false}/>
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime/>
            <DateFieldMod addLabel={true} label="Last Edited At" source="lastEditedAt" showTime/>
            <AdminField label="Created By" source="createdBy" sortable={false}/>
            <AdminField label="Last Edited By" source="lastEditedBy" sortable={false}/>
            {/*<VerticalToolbar {...props} >*/}
            {/*    <ShowButton />*/}
            {/*    <EditButtonMod permissions={[CONTENT_MANAGER]} />*/}
            {/*</VerticalToolbar>*/}
        </Datagrid>
    </ListMod>
}

const BatchShow = ({source, ...props}) => {

    return <SimpleShowLayout {...props}>
        <ReferenceArrayField label="Questions" reference={sharingsQuestion.name} source="questions">
            <SingleFieldList linkType="edit" className={props.className}>
                <ChipFieldMod source="text" color="secondary" variant="outlined"/>
            </SingleFieldList>
        </ReferenceArrayField>
    </SimpleShowLayout>
}