import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {InterestsCategoryList} from "./list";
import {InterestCategoryShow} from "./show";
import {InterestsCategoryCreate} from "./create";
import {InterestsCategoryEdit} from "./edit";

export default {
    name: "admin/attributes/interests-categories",
    list: InterestsCategoryList,
    show: InterestCategoryShow,
    create: HasPermissionsForAction(InterestsCategoryCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(InterestsCategoryEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Interests Categories',
        icon: LibraryBooksIcon,
    }
};