import * as React from 'react';
import {Fragment, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Confirm,
    useDelete,
    useNotify,
    useRefresh,
    useTranslate
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import {useRedirect} from "ra-core/esm/sideEffect";
import {CRUD_DELETE} from "ra-core/lib/actions/dataActions/crudDelete";
import {HasPermissions} from "../../Handlers/HasPermissions";

const styles = {
    button: {
        '&:hover': {
            backgroundColor: 'rgba(244, 67, 54, 0.12)',
        },
        '& svg': {
            color: '#f44336',
        },
        '& span': {
            color: '#f44336',
        }
    },
};

const DeleteButtonWithConfirm = withStyles(styles)(({
    classes,
    route,
    redirectTo,
    record,
    deletePropId = 'id',
    deleteContent = "custom.delete.content",
    deleteTitle = "custom.delete.title",
    permissions = null
}) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const translate = useTranslate();
    const access = permissions ? HasPermissions(permissions) : true;

    const [deleteOne, { loading }] = useDelete(route, null, null,
        {
            action: CRUD_DELETE,
            onSuccess: () => {
                notify('ra.notification.deleted', 'info', { smart_count: 1 });
                redirect(redirectTo, route);
                refresh();
            },
            onFailure: error => {
                notify(typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error', 'warning');
            },
            undoable: false,
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        deleteOne({
            payload: {
                id: record[deletePropId],
                previousData: record
            },
        });
        setOpen(false);
    };

    return (access && <Fragment>
            <Button label="Delete" onClick={handleClick} className={classes.button}><DeleteIcon/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate(deleteTitle, record)}
                content={translate(deleteContent, record)}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
});

export default DeleteButtonWithConfirm;