export const CHAT_TYPE_MYSTERY = 1;
export const CHAT_TYPE_LIGHT = 2;

export const ChatType = [
    { id: CHAT_TYPE_MYSTERY, name: 'Mystery' },
    { id: CHAT_TYPE_LIGHT, name: 'Light' }
];

export const ChatStatus = [
    { id: 1, name: 'Active' },
    { id: 2, name: 'Closed' }
];