import React from "react";
import {number, maxValue, minValue, SearchInput} from "react-admin";

const SearchByUserId = (props) => (
    <SearchInput placeholder="User ID"
                 type="number"
                 validate={[number(), minValue(1), maxValue(2147483647)]}
                 {...props} />
);

export default SearchByUserId;