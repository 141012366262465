import ReportIcon from "@material-ui/icons/Report";
import {ApproachesReportsList} from "./list";
import {ApproachReportShow} from "./show";

export default {
    name: "admin/approaches/reports",
    list: ApproachesReportsList,
    show: ApproachReportShow,
    options: {
        label: 'Reports',
        icon: ReportIcon,
    }
};