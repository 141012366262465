import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    EmailField,
    RichTextField,
} from 'react-admin';
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import ChangeStatusButton from "./Component/ChangeStatusButton";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";

export const FeedbackShow = ( {permissions, ...props} ) => (
    <Show title="Support Request View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            {HasPermissions([SUPPORT_ADMIN]) &&
                <UserField addLabel={true} label="User" source="accountId" />
            }
            {HasPermissions([SUPPORT_ADMIN]) &&
                <EmailField label="Email" source="email" />
            }
            <RichTextField label="Feedback" source="text" style={{overflowWrap: 'anywhere'}}/>
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
            <ChangeStatusButton label="Status" addLabel={true} />
        </SimpleShowLayout>
    </Show>
);
