import React, {cloneElement} from 'react';
import {
    Datagrid,
    ReferenceField,
    TextField,
    FunctionField,
    ChipField,
    RichTextField,
    SimpleShowLayout,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import ReportsFilter from "./Component/ReportsFilter";
import ChangeStatusButton from "../Reports/Component/ChangeStatusButton";
import ExportReportButton from "./Component/ExportReportButton";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {ADMIN, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";
import countries from "../../Countries/Countries";
import reports from "../Reports";

export const ReportsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<ReportsFilter />}
          title="List of Feedbacks"
          basePath={`/${reports.name}`}
         actions={<ListActions permissions={permissions} />}
    >
        <ReportDataGrid/>
    </ListMod>
);

const ReportDataGrid = props => {
    if (!HasPermissions([SUPPORT_ADMIN])) {
        return <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" sortBy="id" />
            <ChipField label="Feedback Option" source="option" color="secondary" />
            <ChangeStatusButton label="Status" />
        </Datagrid>
    }

    return <Datagrid expand={<PostShow />}>
        <TextField label="ID" source="id" sortBy="id" />
        <ChipField label="Feedback Option" source="option" color="secondary" />

        <UserField label="Reporting User" source="reporter" sortable={false} />
        <ReferenceField label="Reporting Country" source="reporterCountryId" reference={countries.name} sortable={false} link="show">
            <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Reporting Phone" render={record => record.reporterPhone ? `${record.reporterPhoneCode}${record.reporterPhone}` : ``} sortable={false} />

        <UserField label="Reported User" source="reported" sortable={false} />
        <ReferenceField label="Reported Country" source="reportedCountryId" reference={countries.name} sortable={false} link="show">
            <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Reported Phone" render={record => record.reportedPhone ? `${record.reportedPhoneCode}${record.reportedPhone}` : ``} sortable={false} />

        <ChangeStatusButton label="Status" />
        <ShowButton />
    </Datagrid>
}

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField label="Feedback" source="text" style={{overflowWrap: 'anywhere'}}/>
        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
    </SimpleShowLayout>
);

const ListActions = ({permissions, ...props}) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {resource, displayedFilters, filterValues, showFilter} = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button'})}
            <ExportReportButton filterValues={filterValues} permissions={[ADMIN]} />
        </TopToolbar>
    );
};