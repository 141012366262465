import AssignmentIcon from "@material-ui/icons/Assignment";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {LifePurposesCategoryList} from "./list";
import {LifePurposeCategoryShow} from "./show";
import {LifePurposeCategoryCreate} from "./create";
import {LifePurposeCategoryEdit} from "./edit";

export default {
    name: "admin/attributes/life-purposes-categories",
    list: LifePurposesCategoryList,
    show: LifePurposeCategoryShow,
    create: HasPermissionsForAction(LifePurposeCategoryCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(LifePurposeCategoryEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Life Purposes Categories',
        icon: AssignmentIcon,
    }
};