import ReportIcon from "@material-ui/icons/Report";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {ChatReportTypeCreate} from "./create";
import {ChatReportTypeEdit} from "./edit";
import {ChatReportTypesList} from "./list";
import {ChatReportTypeShow} from "./show";

export default {
    name: "admin/chats/report/types",
    list: ChatReportTypesList,
    show: ChatReportTypeShow,
    create: ChatReportTypeCreate,
    edit: ChatReportTypeEdit,
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Chat Report Types',
        icon: ReportIcon
    }
};
