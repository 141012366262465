import React, {useState, useCallback, useEffect} from "react";
import {useDataProvider} from 'react-admin';
import {CardAvgAge} from './card/AgeCardWithIcon';
import {CardCountUsersWithOpenedPublicProfile} from './card/OpenedProfileCardWithIcon';
import {GendersChart} from "./card/GendersChart";
import {MatchesByDateChart} from "./card/MatchesByDateChart";
import {RevealsByDateChart} from "./card/RevealsByDateChart";
import {GoalsChart} from "./card/GoalsChart";
import {makeStyles} from "@material-ui/core/styles";
import dashboard from "../Basic";

const useListStyles = makeStyles(theme => ({
    flex: { display: 'flex' },
    dataCol: { flex: 1, marginRight: '1em' },
    col: { flex: 1, marginLeft: '1em', marginRight: '1em' },
    listItem: { textAlign: 'center' },
}));

const Spacer = () => <div style={{ width: '1em' }} />;
const VerticalSpacer = () => <div style={{ height: '1em' }} />;

export const Dashboard = () => {
    const classes = useListStyles();
    const dataProvider = useDataProvider();
    const [dashboardData, setDashboardData] = useState({
        countUsersOpenedPublicProfile: 0,
        avgAge: 0,
        genderPie: [],
        goalPie: [],
        matches: [],
        reveals: [],
    });

    const fetchDashboard = useCallback(async () => {
        return await dataProvider.getStatistic(`${dashboard.name}`, {})
            .then(({ data }) => {
                setDashboardData(dashboardData => {
                    return {...dashboardData, ...data}
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, [dataProvider]);

    useEffect(() => {fetchDashboard()}, [fetchDashboard]);

    const {
        countUsersOpenedPublicProfile,
        avgAge,
        genderPie,
        goalPie,
        matches,
        reveals
    } = dashboardData;

    return <>
        <div className={classes.flex}>
            <div className={classes.dataCol}>
                <div className={classes.flex}>
                    <div className={classes.col}>
                        <div className={classes.flex}>
                            <CardCountUsersWithOpenedPublicProfile value={countUsersOpenedPublicProfile} />
                            <Spacer />
                            <CardAvgAge value={avgAge} />
                        </div>
                    </div>
                </div>
                <VerticalSpacer />
                <div className={classes.flex}>
                    <div className={classes.col}>
                        <div className={classes.flex}>
                            <GendersChart data={genderPie} />
                            <Spacer/>
                            <GoalsChart data={goalPie} />
                        </div>
                    </div>
                </div>
                <VerticalSpacer/>
                <div className={classes.flex}>
                    <div className={classes.col}>
                        <div className={classes.flex}>
                            <MatchesByDateChart data={matches} />
                        </div>
                    </div>
                </div>
                <VerticalSpacer/>
                <div className={classes.flex}>
                    <div className={classes.col}>
                        <div className={classes.flex}>
                            <RevealsByDateChart data={reveals} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default Dashboard;
