import MenuBookIcon from "@material-ui/icons/MenuBook";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {EndSessionCommentCreate} from "./create";
import {EndSessionCommentEdit} from "./edit";
import {EndSessionCommentsList} from "./list";
import {EndSessionCommentShow} from "./show";

export default {
    name: "admin/chats/end-session/comments",
    list: EndSessionCommentsList,
    show: EndSessionCommentShow,
    create: EndSessionCommentCreate,
    edit: EndSessionCommentEdit,
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'End Session Comments',
        icon: MenuBookIcon
    }
};
