import {ConfigsList} from "./list";
import {ConfigShow} from "./show";
import {ConfigEdit} from "./edit";
import {ADMIN} from "../../../App/Component/Constant/Roles";
import SettingsIcon from "@material-ui/icons/Settings";

export default {
    name: "admin/configs",
    list: ConfigsList,
    show: ConfigShow,
    edit: ConfigEdit,
    permissions: [ADMIN],
    options: {
        label: 'Configs',
        icon: SettingsIcon
    }
};
