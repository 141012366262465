import React from "react";
import { MenuItemLink } from 'react-admin';
import { HasPermissions } from "../Handlers/HasPermissions";
import { createElement } from 'react';
import {useSelector} from "react-redux";

const MenuItemLinkMod = ({name, onClick, options, permissions = null}) => {
    const access = permissions ? HasPermissions(permissions) : true;
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    return access && <MenuItemLink
        key={name}
        to={`/${name}`}
        primaryText={options.label}
        leftIcon={createElement(options.icon)}
        onClick={onClick}
        sidebarIsOpen={open}
    />;
};

export default MenuItemLinkMod;