export const MIN_AGE  = 18;
export const MAX_AGE = 120;
export const MAX_AGE_INDICATOR = 91;
export const MIN_DISTANCE = 2;
export const MAX_DISTANCE = 170;

export const USER_STATUS_CREATED = 1;
export const USER_STATUS_INVITED = 2;
export const USER_STATUS_PENDING = 4;
export const USER_STATUS_ACTIVATED = 5;
export const USER_STATUS_DELETED = 9;
export const USER_STATUS_BLOCKED = 10;

export const UserStatus = [
    { id: USER_STATUS_CREATED, name: 'Created' },
    { id: USER_STATUS_INVITED, name: 'Invited' },
    { id: USER_STATUS_PENDING, name: 'Pending' },
    { id: USER_STATUS_ACTIVATED, name: 'Activated' },
    { id: USER_STATUS_DELETED, name: 'Deleted' },
    { id: USER_STATUS_BLOCKED, name: 'Blocked' },
];

export const ConnectionStatus = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' },
];
export const PremiumStatus = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' },
];

export const INVITE_TYPE_MARKET = 'market';
export const INVITE_TYPE_USER = 'user';
export const INVITE_TYPE_COMMUNITY = 'community';

export const InviteTypes = [
    { id: INVITE_TYPE_MARKET, name: 'Market' },
    { id: INVITE_TYPE_USER, name: 'Invite from User' },
    { id: INVITE_TYPE_COMMUNITY, name: 'Community' },
];