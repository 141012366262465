import React from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    EmailField,
    FunctionField,
    ReferenceArrayField,
    ReferenceField,
    ReferenceManyField,
    SelectField,
    ShowController,
    ShowView,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import {InviteTypes, MAX_AGE, MAX_DISTANCE, MIN_AGE, UserStatus} from "./constant/userConst";
import SourceEntityField from "./Component/SourceEntityField";
import ChipReferralsField from "./Component/ChipReferralsField";
import PaginationMod from "../../../App/Component/Redesign/PaginationMod";
import {ChatStatus, ChatType} from "../../Chats/Chats/constant/chatConst";
import ChatUserField from "./Component/ChatUserField";
import UserField from "./Component/UserField";
import ChipDeviceTypeField from "./Component/ChipDeviceTypeField";
import countries from "../../Countries/Countries";
import communities from "../../Communities/Communities";
import languages from "../../Languages";
import relationships from "../../Relationships";
import identityGenders from "../../IdentityGenders";
import chatsList from "../../../Pages/Chats/Chats";
import goals from "../../../Pages/Goals";
import dealBreakers from "../../../Pages/Attributes/DealBreakers";
import interests from "../../../Pages/Attributes/Interests";
import lifePurposes from "../../../Pages/Attributes/LifePurposes";
import spiritualPractices from "../../../Pages/Attributes/SpiritualPractices";
import cities from "../../Cities";

export const UserShow = ( {permissions, ...props} ) => (
    <ShowController {...props} >
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <TabbedShowLayout>
                    <Tab label="General">
                        <TextField label="ID" source="id" />
                        <TextField label="Name" source="name" />
                        <DateFieldMod addLabel={true} label="DoB" source="birthday" />
                        <ReferenceField label="Country" source="countryId" reference={countries.name} link="show">
                            <FunctionField
                                render={record => `${record.name} ${record.flag}`}
                            />
                        </ReferenceField>
                        <ReferenceField label="City" source="cityId" reference={cities.name} link="show">
                            <FunctionField
                                render={record => `${record.name} (${record.stateName})`}
                            />
                        </ReferenceField>
                        <FunctionField label="Phone" render={record => record.phone ? `${record.phoneCode}${record.phone}` : ``} />
                        <EmailField label="Email" source="email" />
                        <BooleanField label="Connection status" source="connectionStatus" valueLabelTrue="Active" valueLabelFalse="Inactive" />
                        <BooleanField label="Premium status" source="premiumStatus" sortable={false} valueLabelTrue="Active" valueLabelFalse="Inactive" />
                        <SelectField label="User status" source="status" choices={UserStatus} />
                        <ReferenceArrayField label="Languages" reference={languages.name} source="languages">
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceField label="Gender" source="gender" reference={identityGenders.name} link={false}>
                            <TextField source="title" />
                        </ReferenceField>
                        <ReferenceArrayField label="Relationship status" source="relationships" reference={relationships.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Communities" source="communities" reference={communities.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ArrayField label="Device OS" source="devices" sortable={false}>
                            <SingleFieldList linkType={false}>
                                <ChipDeviceTypeField source="type" color="secondary" />
                            </SingleFieldList>
                        </ArrayField>
                        <SelectField label="Source of registration" source="invitationType" choices={InviteTypes} />
                        <SourceEntityField addLabel={true} label="Source entity" />
                        <ChipReferralsField addLabel={true} label="Count Referrals" />
                        <BooleanField label="Is influencer" source="influencer" valueLabelTrue="Yes" valueLabelFalse="No" />
                        <ReferenceArrayField label="Leading communities" source="leadingCommunities" reference={communities.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <DateFieldMod addLabel={true} label="Last Activity At" source="lastOnlineAt" showTime />
                        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
                    </Tab>
                    <Tab label="Filters" path="filters">
                        <FunctionField label="Distance" render={
                            record => record.filters && record.filters.distance && record.filters.distance !== MAX_DISTANCE  ?
                                `${record.filters.distance} km` :
                                `Global`
                        } />
                        <FunctionField label="Age" render={
                            record => record.filters ?
                                `${record.filters.minAge ?? MIN_AGE} <> ${record.filters.maxAge && record.filters.maxAge !== MAX_AGE ? record.filters.maxAge : 'Max'}` :
                                `All`
                        } />
                        <ReferenceArrayField label="With" source="filters.genders" reference={identityGenders.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="pluralTitle" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="For" source="filters.goals" reference={goals.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Community" source="filters.communities" reference={communities.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <DateFieldMod addLabel={true} label="Last time set filters" source="filters.updatedAt" showTime />
                        Match counter
                        <TextField label="Count matches" source="filters.countChats" />
                        <UserField addLabel={true} label="Last match with" source="filters.lastChatUser" />
                        <DateFieldMod addLabel={true} label="Last match at" source="filters.lastChatAt" showTime />
                    </Tab>
                    <Tab label="Attributes" path="attributes">
                        <ReferenceArrayField label="Interests" source="attributes.interests" reference={interests.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Deal breakers" source="attributes.dealBreakers" reference={dealBreakers.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="dealBreakerTitle" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="A bit about me" source="attributes.dailyLifes" reference={dealBreakers.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="dailyLifeTitle" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Spiritual practice" source="attributes.spiritualPractices" reference={spiritualPractices.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                        <ReferenceArrayField label="Life purposes" source="attributes.lifePurposes" reference={lifePurposes.name}>
                            <SingleFieldList linkType={false}>
                                <ChipField source="title" color="secondary" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </Tab>
                    <Tab label="Chats" path="chats">
                        <ReferenceManyField reference={chatsList.name} target="userId" pagination={<PaginationMod />} addLabel={false}>
                            <Datagrid>
                                <TextField label="ID" source="id" />
                                <ChatUserField label="With User" sortable={false} controllerProps={controllerProps} />
                                <SelectField label="Type" source="type" choices={ChatType} sortable={false} />
                                <SelectField label="Status" source="status" choices={ChatStatus} sortable={false} />
                                <DateFieldMod label="Created At" source="createdAt" showTime />
                                <DateFieldMod label="Last Activity At" source="lastMessageAt" showTime />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                </TabbedShowLayout>
            </ShowView>
        }
    </ShowController>
);
