import React from 'react';
import {
    TextField,
    SelectField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import {ChallengeGroups, ChallengeTypes} from "./constant/challengeConst";
import ChangeStatusButton from "./Component/ChangeStatusButton";

export const ChallengeShow = ( {permissions, ...props} ) => (
    <Show title="Challenge View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" />
            <SelectField label="Type" source="type" choices={ChallengeTypes} />
            <SelectField label="Group" source="group" choices={ChallengeGroups} />
            <ChangeStatusButton label="Status" addLabel={true} />
        </SimpleShowLayout>
    </Show>
);
