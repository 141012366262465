import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import ValueInput from "../PublicConfigs/Component/ValueInput";

export const ConfigEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth disabled label="Name" source="showName" />
            <ValueInput fullWidth label="Value" source="value" multiline={true} />
        </SimpleForm>
    </Edit>;
};