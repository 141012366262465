import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import CountriesFilter from "./Component/CountriesFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {ADMIN} from "../../../App/Component/Constant/Roles";
import countries from "../../Countries/Countries";

export const CountriesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<CountriesFilter />}
          title="List of Countries"
          basePath={`/${countries.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Dial code" source="dialCode" />
            <TextField label="Code" source="code" />
            <TextField label="Flag" source="flag" sortable={false} />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[ADMIN]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);