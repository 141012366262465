import React from 'react';
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";
import {CommunityStatus} from "../constant/communityConst.js";
import DeleteButtonMod from "../../../../App/Component/Redesign/Button/DeleteButtonMod";

const DeactivateButton = ({ ...props }) => {
    return props.record && props.record.status !== ValueFromConst(CommunityStatus, 'Deactivate') ?
        <DeleteButtonMod { ...props } label="Deactivate" /> : '';
};

export default DeactivateButton;