import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import VerticalToolbar from "../../App/Component/Redesign/VerticalToolbar";
import ListMod from "../../App/Component/Redesign/ListMod";
import GoalsFilter from "./Component/GoalsFilter";
import goals from "../../Pages/Goals";

export const GoalsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<GoalsFilter />}
          title="List of Goals"
          basePath={`/${goals.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <VerticalToolbar {...props}>
                <ShowButton />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);