import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import {SUPPORT_ADMIN, VIEWER} from "../../../App/Component/Constant/Roles";
import MatchingStatistic from "./MatchingStatistic";

export default {
    name: "admin/matching",
    list: MatchingStatistic,
    permissions: [SUPPORT_ADMIN, VIEWER],
    options: {
        label: 'Matching State',
        icon: RecordVoiceOverIcon
    },
};