import SettingsIcon from "@material-ui/icons/Settings";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {PublicConfigsList} from "./list";
import {PublicConfigShow} from "./show";
import {PublicConfigEdit} from "./edit";

export default {
    name: "admin/settings",
    list: PublicConfigsList,
    show: PublicConfigShow,
    edit: PublicConfigEdit,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Settings',
        icon: SettingsIcon
    }
};
