import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ImageInput,
    NumberInput
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateDescription, validateName} from "./validation/validation";
import PreviewImageField from "../../../App/Component/Redesign/Field/PreviewImageField";

const transform = data => ({
    ...data,
    updateMethod: 'POST'
});

export const NounEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic" transform={transform}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <TextInput fullWidth label="Description" source="description" validate={validateDescription} />
            <ImageInput source="image"
                        label="Image"
                        accept="image/png"
                        validate={[required()]}
                        maxSize={5242880}
                        placeholder={
                            <p>
                                Drop a picture to upload, or click to select it.<br/>
                                Maximum file size for uploads is 5MB.<br/>
                                Available type(s) of files: png.
                            </p>
                        }>
                <PreviewImageField source="image" title="Image" />
            </ImageInput>
        </SimpleForm>
    </Edit>;
};