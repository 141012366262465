import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import ChipMembersField from "./Component/ChipMembersField";
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";
import DeepLinkField from "./Component/DeepLinkField";
import UserField from "../../Users/Users/Component/UserField";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";

export const CommunityShow = ( {permissions, ...props} ) => (
    <Show title="Community View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <TextField label="Key" source="key" />
            {HasPermissions([SUPPORT_ADMIN]) &&
                <UserField addLabel={true} label="Leader" source="leaderId" />
            }
            <ChipMembersField addLabel={true} label="Number of users" />
            <TextField label="Code" source="code" />
            <DeepLinkField addLabel={true} label="Deep Link" />
            <ImageFieldMod label="Image" addLabel={true} source="image" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
