import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import SubMenu from "./SubMenu";
import MenuItemLinkMod from "./MenuItemLinkMod";

import dashboard from "../../../Pages/Statistics/Basic";
import matching from "../../../Pages/Statistics/Matching";
import users from "../../../Pages/Users/Users";
import admins from "../../../Pages/Users/Admins";
import challenges from "../../../Pages/Challenges/Challenges";
import communities from "../../../Pages/Communities/Communities";
import reports from "../../../Pages/Reports/Reports";
import feedbacks from "../../../Pages/Feedbacks/Feedbacks";
import attributes from "../../../Pages/Attributes";
import dealBreakers from "../../../Pages/Attributes/DealBreakers";
import interests from "../../../Pages/Attributes/Interests";
import interestsCategory from "../../../Pages/Attributes/InterestsCategory";
import lifePurposesCategory from "../../../Pages/Attributes/LifePurposesCategory";
import lifePurposes from "../../../Pages/Attributes/LifePurposes";
import spiritualPractices from "../../../Pages/Attributes/SpiritualPractices";
import archetypes from "../../../Pages/Archetypes";
import nouns from "../../../Pages/Archetypes/Nouns";
import adjectives from "../../../Pages/Archetypes/Adjectives";
import dictionaries from "../../../Pages/Dictionaries";
import countries from "../../../Pages/Countries/Countries";
import languages from "../../../Pages/Languages";
import identityGenders from "../../../Pages/IdentityGenders";
import goals from "../../../Pages/Goals";
import relationships from "../../../Pages/Relationships";
import chats from "../../../Pages/Chats";
import chatsList from "../../../Pages/Chats/Chats";
import chatReportTypes from "../../../Pages/Chats/ReportTypes";
import endSessionComments from "../../../Pages/Chats/EndSessionComments";
import reveals from "../../../Pages/Reveals";
import revealFeedbacks from "../../../Pages/Reveals/Feedbacks";
import revealFeedbackOptions from "../../../Pages/Reveals/FeedbackOptions";
import publicConfigs from "../../../Pages/Configs/PublicConfigs";
import sharings from "../../../Pages/Sharings";
import approaches from "../../../Pages/Approaches";
import sharingsQuestions from "../../../Pages/Sharings/Questions";
import sharingsBatches from "../../../Pages/Sharings/Batches";
import sharingsReportTypes from "../../../Pages/Sharings/ReportTypes";
import approachesReports from "../../../Pages/Approaches/Reports";
import approachesReportTypes from "../../../Pages/Approaches/ReportTypes";
import cities from "../../../Pages/Cities";

const CustomMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const [state, setState] = useState({
        menuAttribute: false,
        menuDictionary: false,
        menuChat: false,
        menuArchetype:false,
        menuReveal: false,
        menuSharing: false,
        menuApproach: false,
    });
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box mt={1}>
            <MenuItemLinkMod onClick={onMenuClick} {...dashboard} />
            <MenuItemLinkMod onClick={onMenuClick} {...matching} />
            <MenuItemLinkMod onClick={onMenuClick} {...users} />
            <MenuItemLinkMod onClick={onMenuClick} {...admins} />
            <MenuItemLinkMod onClick={onMenuClick} {...challenges} />
            <MenuItemLinkMod onClick={onMenuClick} {...communities} />
            <MenuItemLinkMod onClick={onMenuClick} {...reports} />
            <MenuItemLinkMod onClick={onMenuClick} {...feedbacks} />
            <SubMenu
                handleToggle={() => handleToggle('menuAttribute')}
                isOpen={state.menuAttribute}
                {...attributes}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...interests} />
                <MenuItemLinkMod onClick={onMenuClick} {...interestsCategory} />
                <MenuItemLinkMod onClick={onMenuClick} {...dealBreakers} />
                <MenuItemLinkMod onClick={onMenuClick} {...spiritualPractices} />
                <MenuItemLinkMod onClick={onMenuClick} {...lifePurposes} />
                <MenuItemLinkMod onClick={onMenuClick} {...lifePurposesCategory} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuArchetype')}
                isOpen={state.menuArchetype}
                {...archetypes}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...nouns} />
                <MenuItemLinkMod onClick={onMenuClick} {...adjectives} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuDictionary')}
                isOpen={state.menuDictionary}
                {...dictionaries}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...countries} />
                <MenuItemLinkMod onClick={onMenuClick} {...cities} />
                <MenuItemLinkMod onClick={onMenuClick} {...languages} />
                <MenuItemLinkMod onClick={onMenuClick} {...identityGenders} />
                <MenuItemLinkMod onClick={onMenuClick} {...relationships} />
                <MenuItemLinkMod onClick={onMenuClick} {...goals} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuReveal')}
                isOpen={state.menuReveal}
                {...reveals}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...revealFeedbacks} />
                <MenuItemLinkMod onClick={onMenuClick} {...revealFeedbackOptions} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuChat')}
                isOpen={state.menuChat}
                {...chats}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...chatsList} />
                <MenuItemLinkMod onClick={onMenuClick} {...endSessionComments} />
                <MenuItemLinkMod onClick={onMenuClick} {...chatReportTypes} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSharing')}
                isOpen={state.menuSharing}
                {...sharings}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...sharingsQuestions} />
                <MenuItemLinkMod onClick={onMenuClick} {...sharingsBatches} />
                <MenuItemLinkMod onClick={onMenuClick} {...sharingsReportTypes} />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuApproach')}
                isOpen={state.menuApproach}
                {...approaches}
            >
                <MenuItemLinkMod onClick={onMenuClick} {...approachesReports} />
                <MenuItemLinkMod onClick={onMenuClick} {...approachesReportTypes} />
            </SubMenu>
            <MenuItemLinkMod onClick={onMenuClick} {...publicConfigs} />
            {isXSmall && logout}
        </Box>
    );
}

export default withRouter(CustomMenu);
