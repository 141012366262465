import React from 'react';
import {PasswordInput} from "react-admin";
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import {validateRepeatPasswordEdit} from "../validation/validation";
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";

const RepeatPasswordEditInput = ({permissions, ...props}) => {
    if (props.record && props.record.role !== ValueFromConst(AdminRolesList, 'Admin')) {
        return <PasswordInput {...props} fullWidth source="repeatPassword" validate={validateRepeatPasswordEdit} />;
    }

    return null;
};

export default RepeatPasswordEditInput;
