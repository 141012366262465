import * as React from "react";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {stringify} from 'qs';
import CardWithIcon from "../../Component/CardWithIcon";
import users from "../../../Users/Users";

export const getRedirectUrl = (filters = {}) => {
    const redirectFilter = {...filters};

    return {
        pathname: `/${users.name}`,
        search: stringify({
            filter: JSON.stringify(redirectFilter),
        }),
    };
};

export const CardCountUsersWithOpenedPublicProfile = ({ value, filters = {} }) => {
    return <CardWithIcon to={getRedirectUrl(filters)} icon={AssignmentIndIcon} subtitle={value} title={'Count Users With Opened Public Profile'} />
};