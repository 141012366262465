import React from 'react';
import {TextInput} from 'react-admin';
import {validateEmail} from "../validation/validation";

const EmailInput = ({ ...props}) => {
    let emailChangeAllowed = true;
    if (props.record){
        emailChangeAllowed = props.record.emailVerified;
    }
    return <TextInput disabled={emailChangeAllowed} fullWidth label="Email" source="email" type="email" validate={validateEmail} variant="outlined"/>
};

export default EmailInput;