import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {InterestsList} from "./list";
import {InterestShow} from "./show";
import {InterestCreate} from "./create";
import {InterestEdit} from "./edit";

export default {
    name: "admin/attributes/interests",
    list: InterestsList,
    show: InterestShow,
    create: HasPermissionsForAction(InterestCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(InterestEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Interests',
        icon: LibraryAddCheckIcon,
    }
};