import Chart from "chart.js";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect} from "react";

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
}));

const CardChart = (props) => {
    const classes = useStyles(props);
    const { id, title, type, label, labels, data, backgroundColor, borderColor, borderWidth = 1, legend = true} = props;

    useEffect(() => {
        new Chart(id, {
            type: type,
            data: {
                labels: labels,
                datasets: [{
                    label: label,
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    fill: false,
                    borderWidth: borderWidth
                }]
            },
            options: {
                plugins: {
                    legend: legend,
                },
                elements: {
                    line: {
                        tension: 0.4,
                    },
                },
            }
        });
    });

    return (
        <Card className={classes.card}>
            <CardHeader title={title} />
            <CardContent>
                <canvas id={id}>
                </canvas>
            </CardContent>
        </Card>
    );
};

export default CardChart;