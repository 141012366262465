import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import ChipInterestsField from "./Component/ChipInterestsField";
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";

export const InterestCategoryShow = ( {permissions, ...props} ) => (
    <Show title="Interest Category View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ImageFieldMod label="Image" addLabel={true} source="image" sortable={false} />
            <ChipInterestsField addLabel={true} label="Count Interests" source="count" />
        </SimpleShowLayout>
    </Show>
);
