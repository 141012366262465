import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {DateTimeInput} from "@shinabr2/react-admin-date-inputs";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {InputAdornment, makeStyles} from "@material-ui/core";

const customStyles = makeStyles({
    element: {
        marginBottom: '-15px'
    },
    dateInput: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginTop: '0 !important',
        "&:focused": {
            backgroundColor: 'rgba(0, 0, 0, 0.13)',
        },
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.13)',
        },
    },
    dateInputLabel: {
        "&.shrink": {
            transform: 'translate(0, 12px) scale(0.75)',
        },
    },
});

const DateTimeInputMod = props => {
    const classes = customStyles();

    return <DateTimeInput
            className={classes.element}
            providerOptions={{ utils: DateFnsUtils }}
            label={props.label}
            source={props.source}
            options={{
                size: 'small',
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <DateRangeIcon />
                        </InputAdornment>
                    ),
                    classes: {
                        root: classes.dateInput,
                        focused: "focused",
                    },
                },
                InputLabelProps: {
                    classes: {
                        root: classes.dateInputLabel,
                        shrink: "shrink"
                    }
                },
                autoOk: true,
                clearable: true,
                format: 'dd/MM/yyyy HH:mm:ss',
                clearLabel: 'CLEAR',
                cancelLabel: 'CANCEL',
            }} />
};

export default DateTimeInputMod;