import * as React from "react";
import EventIcon from '@material-ui/icons/Event';
import {stringify} from 'qs';
import CardWithIcon from "../../Component/CardWithIcon";
import users from "../../../Users/Users";

export const getRedirectUrl = (filters = {}) => {
    const redirectFilter = {...filters};

    return {
        pathname: `/${users.name}`,
        search: stringify({
            filter: JSON.stringify(redirectFilter),
        }),
    };
};

export const CardAvgAge = ({ value, filters = {} }) => {
    return <CardWithIcon to={getRedirectUrl(filters)} icon={EventIcon} subtitle={value + ' year(s)'} title={'Avg Age'} />
};