import React from 'react';
import {DateField} from "react-admin";
import {DateTimeFormatString} from "../../Handlers/DateTimeFormat";

const DateFieldMod = props => {
    /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
    const value = props.source.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, props.record);
    if (props.record && value) {
        props.record['dateMod'] = DateTimeFormatString(value);
    }

    return <DateField {...props} source="dateMod" locales="en-GB" />;
};

export default DateFieldMod;