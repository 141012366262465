import React from "react";
import {ChipField} from 'react-admin';
import IconField from "../../Questions/Component/IconField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    questionsList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
});

const ChipFieldMod = (props) => {
    const classes = useStyles();
    const {source} = props;
    return <div className={classes.questionsList}>
        <IconField source="category"/>
        <ChipField source={source} color="secondary" variant="outlined"/>
    </div>
}

export default ChipFieldMod;