import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import {ADMIN} from "../../../App/Component/Constant/Roles";
import {AdminsList} from "./list";
import {AdminShow} from "./show";
import {AdminEdit} from "./edit";
import {AdminCreate} from "./create";

export default {
    name: "admin/admins",
    list: AdminsList,
    show: AdminShow,
    create: AdminCreate,
    edit: AdminEdit,
    permissions: [ADMIN],
    options: {
        label: 'Internal Team',
        icon: PeopleOutlineIcon
    },
};