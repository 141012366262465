import React from "react";
import CardChart from "../../Component/CardChart";

export const RevealsByDateChart = (props) => {
    const { data } = props;
    const id = 'revealsChart';
    const labels = data ? data.map(a => a.date): [];
    const values = data ? data.map(a => a.count) : [];

    const options = {
        type: 'line',
        label: 'Reveals',
        title: 'Reveals by Date',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        labels: labels,
    };

    return (
        <CardChart id={id} data={values} {... options} />
    );
}