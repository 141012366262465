import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import ChipLifePurposesField from "./Component/ChipLifePurposesField";

export const LifePurposeCategoryShow = ( {permissions, ...props} ) => (
    <Show title="Life Purpose Category View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ChipLifePurposesField addLabel={true} label="Count Interests" source="count" />
        </SimpleShowLayout>
    </Show>
);
