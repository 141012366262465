import React from 'react';
import UserField from "./UserField";

const ChatUserField = ({controllerProps, ...props}) => {
    if (props.record && props.record.userId === controllerProps.record.id) {
        return <UserField {...props} source="ownerId" />
    }

    return <UserField {...props} source="userId" />
}

export default ChatUserField;