import LocationOnIcon from "@material-ui/icons/LocationOn";
import {ADMIN, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {CountriesList} from "./list";
import {CountryShow} from "./show";
import {CountryEdit} from "./edit";

export default {
    name: "admin/countries",
    list: CountriesList,
    show: CountryShow,
    edit: HasPermissionsForAction(CountryEdit, [ADMIN]),
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Countries',
        icon: LocationOnIcon
    }
};