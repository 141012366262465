import React from 'react';
import {
    TextInput,
    SelectInput,
    Edit,
    SimpleForm,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateGroup, validateText, validateType} from "./validation/validation";
import {ChallengeGroups, ChallengeTypes} from "./constant/challengeConst";

export const ChallengeEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput disabled fullWidth label="ID" source="id" />
            <TextInput multiline fullWidth label="Text" source="text" validate={validateText} />
            <SelectInput disabled fullWidth label="Type" source="type" choices={ChallengeTypes} validate={validateType} />
            <SelectInput fullWidth label="Group" source="group" choices={ChallengeGroups} validate={validateGroup} />
        </SimpleForm>
    </Edit>;
};