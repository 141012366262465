import ListMod from "../../../App/Component/Redesign/ListMod";
import sharingsQuestions from "../../Sharings/Questions";
import {Datagrid, ReferenceField, TextField} from "react-admin";
import React from "react";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import QuestionFilter from "./Component/QuestionFilter";
import AdminField from "../../Users/Admins/Component/AdminField";
import IconField from "./Component/IconField";
import sharingsBatches from "./../Batches";
import ChangePopularButton from "./Component/ChangePopularButton";
import ChangeOnboardingButton from "./Component/ChangeOnboardingButton";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";

export const QuestionsList = ({permissions, ...props}) => (
    <ListMod {...props}
             filters={<QuestionFilter />}
             title="List of Questions"
             basePath={`/${sharingsQuestions.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Question Text" source="text" sortable={false} />
            <IconField label="Category" source="category" />
            <ChangePopularButton label="Popular" permissions={[CONTENT_MANAGER]}/>
            <ChangeOnboardingButton label="Onboarding" permissions={[CONTENT_MANAGER]}/>
            <DateFieldMod addLabel={true} label="Publish Date" source="publishDate" showTime={false}/>
            <ReferenceField label="Batch Id" source="batchId" reference={sharingsBatches.name}>
                <TextField source="id"/>
            </ReferenceField>
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
            <DateFieldMod addLabel={true} label="Last Edited At" source="lastEditedAt" showTime />
            <AdminField label="Created By" source="createdBy" sortable={false} />
            <AdminField label="Last Edited By" source="lastEditedBy" sortable={false} />
            <VerticalToolbar {...props} >
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>

);