import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    EmailField,
    SimpleShowLayout,
    RichTextField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import FeedbacksFilter from "./Component/FeedbacksFilter";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import ChangeStatusButton from "./Component/ChangeStatusButton";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";
import feedbacks from "../../../Pages/Feedbacks/Feedbacks";

export const FeedbacksList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<FeedbacksFilter />}
          title="List of Support Requests"
          basePath={`/${feedbacks.name}`}
    >
        <FeedbackDataGrid />
    </ListMod>
);

const FeedbackDataGrid = props => {
    if (!HasPermissions([SUPPORT_ADMIN])) {
        return <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" />
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
            <ChangeStatusButton label="Status" />
            <ShowButton />
        </Datagrid>
    }

    return <Datagrid expand={<PostShow />}>
        <TextField label="ID" source="id" />
        <UserField label="User" source="accountId" sortable={false} />
        <EmailField label="Email" source="email" />
        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
        <ChangeStatusButton label="Status" />
        <ShowButton />
    </Datagrid>
}

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField label="Feedback" source="text"  style={{overflowWrap: 'anywhere'}}/>
    </SimpleShowLayout>
);