import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

const theme = merge(defaultTheme, {
    overrides: {
        MuiCard: {
            root: {
                overflow: 'visible',
            },
        },
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: "#2196f3"
            }
        },
    }
});

export default theme;