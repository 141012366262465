import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    NumberInput
} from 'react-admin';
import SubmitToolbar from "../../App/Component/Redesign/SubmitToolbarMod";
import {validateName, validatePriority} from "./validation/validation";

export const LanguageCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <NumberInput fullWidth label="Priority" source="priority" validate={validatePriority} />
        </SimpleForm>
    </Create>
);