import ChatIcon from "@material-ui/icons/Chat";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {ChatsList} from "./list";

export default {
    name: "admin/chats/list",
    list: ChatsList,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Chats',
        icon: ChatIcon
    },
};