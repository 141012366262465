import BallotIcon from "@material-ui/icons/Ballot";
import {RevealFeedbacksList} from "./list";
import {RevealFeedbackShow} from "./show";

export default {
    name: "admin/reveals/feedbacks",
    list: RevealFeedbacksList,
    show: RevealFeedbackShow,
    options: {
        label: 'Reveal Feedbacks',
        icon: BallotIcon
    }
};
