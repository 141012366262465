import * as React from "react";
import {FunctionField, useListContext} from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    ShowButton,
    TextField,
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import ImageFieldMod from "../../../../App/Component/Redesign/Field/ImageFieldMod";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../../App/Component/Constant/Roles";
import users from "../../../Users/Users";

const useListStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        ...theme.typography.body1,
    },
    cardLink: {
        ...theme.typography.body1,
        borderTop: '1px solid #ececec',
        flexGrow: 1,
    },
    cardLinkLink: {
        display: 'inline',
    },
    cardActions: {
        justifyContent: 'flex-end',
    },
    flex: {
        display: 'flex'
    },
    dataCol: {
        flex: 1,
        marginRight: '1em'
    },
    col: {
        flex: 1,
        marginLeft: '1em',
        marginRight: '1em'
    },
    verticalSpaces: {
        height: '1em'
    },
    avatarStyle: {
        backgroundColor: '#c6e9f6',
        width: '50px',
        height: '50px'
    },
    iconStyle: {
        color: '#00a3e6'
    },
    typographyHeader: {
        fontWeight: 600,
    },
    typography: {
        fontWeight: 600,
    },
    box: {
        marginLeft: '1em',
    }
}));

const GridList = ({permissions, ...props}) => {
    const classes = useListStyles();
    const { ids, data } = useListContext();

    return (
        <Grid spacing={2} container>
            {ids.map(id => (data[id] &&
                <Grid item key={id} sm={6} md={4} lg={3}>
                    <Card className={classes.card}>
                        <CardHeader
                            className="comment"
                            title={<FunctionField record={data[id]} render={record => `${record.avatarName} #${record.id}`}/>}
                            subheader={<FunctionField record={data[id]} render={record => `${record.gender}, ${record.age} year(s)`}/>}
                            avatar={<Avatar className={classes.avatarStyle}>
                                {data[id].image ?
                                    <ImageFieldMod record={data[id]} source="image" sortable={false} elStyle={{image: {maxHeight: '40px', maxWidth: '40px'}}} />:
                                    <PersonIcon className={classes.iconStyle} />
                                }
                            </Avatar>}
                        />
                        <CardContent className={classes.cardContent}>
                            <Typography className={classes.typography} component="span" variant="body2">Communities: </Typography>
                            <FunctionField variant="body2" record={data[id]} render={record => record.communities ? `${record.communities}` : `None`} className={classes.cardLinkLink}/>
                            <br/>
                            <Typography className={classes.typography} component="span" variant="body2">Location: </Typography>
                            <FunctionField variant="body2" record={data[id]} render={record => record.location ? `${record.location}` : `None`} className={classes.cardLinkLink}/>
                        </CardContent>
                        <CardContent className={classes.cardLink}>
                            <Typography className={classes.typographyHeader} component="span" variant="body2" fontWeight="fontWeightBold">Filters: </Typography>
                            <Box className={classes.box}>
                                <Typography className={classes.typography} component="span" variant="body2">Age: </Typography>
                                <TextField record={data[id]} source="filterAge" className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Distance: </Typography>
                                <FunctionField record={data[id]} render={record => record.filterDistance ? `${record.filterDistance} km` : `Everywhere`}  className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Genders: </Typography>
                                <FunctionField record={data[id]} render={record => record.filterGenders ? `${record.filterGenders}` : `All`} className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Goals: </Typography>
                                <FunctionField record={data[id]} render={record => record.filterGoals ? `${record.filterGoals}` : `All`} className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Communities: </Typography>
                                <FunctionField record={data[id]} render={record => record.filterCommunities ? `${record.filterCommunities}`  : `All`} className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Count matches: </Typography>
                                <TextField record={data[id]} source="countChats" className={classes.cardLinkLink}/>
                            </Box>
                        </CardContent>
                        <CardContent className={classes.cardLink}>
                            <Typography className={classes.typographyHeader} component="span" variant="body2" fontWeight="fontWeightBold">Attributes: </Typography>
                            <Box className={classes.box}>
                                <Typography className={classes.typography} component="span" variant="body2">A bit about me: </Typography>
                                <FunctionField record={data[id]} render={record => record.dailyLife ? `${record.dailyLife}`  : `None`} className={classes.cardLinkLink}/>
                                <br/>
                                <Typography className={classes.typography} component="span" variant="body2">Deal breakers: </Typography>
                                <FunctionField record={data[id]} render={record => record.dealBreakers ? `${record.dealBreakers}`  : `None`} className={classes.cardLinkLink}/>
                            </Box>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            {HasPermissions([SUPPORT_ADMIN]) && <ShowButton record={data[id]} basePath={`/${users.name}`} />}
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default GridList;