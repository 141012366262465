import React from 'react';
import {Button, useRefresh, useUpdate} from 'react-admin';
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";
import {AdminStatus} from "../constant/adminConst";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import admins from '../../Admins';

const ActivateButton = ({ record }) => {
    const refresh = useRefresh();

    const [active, { loading }] = useUpdate(
        `${admins.name}`,
        (record ? record.id : null) + '/status',
        { status: ValueFromConst(AdminStatus, 'Activated') },
        record,
        {
            onSuccess: () => { refresh() },
        }
    );

    return record && record.status !== ValueFromConst(AdminStatus, 'Activated')
        ? <Button label="Active" onClick={(e) => {e.stopPropagation(); active();}} disabled={loading}><GroupAddIcon /></Button> : '';
};

export default ActivateButton;