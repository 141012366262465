import * as React from 'react';
import {CreateButton} from 'react-admin';
import {HasPermissions} from "../../Handlers/HasPermissions";

const CreateButtonMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    return access && <CreateButton {...props} undoable={false} />;
};

export default CreateButtonMod;