import React from 'react';
import {
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';
import {ChatStatus, ChatType} from "../constant/chatConst";
import DateTimeInputMod from "../../../../App/Component/Redesign/Input/DateTimeInputMod";
import SearchByUserId from "../../../Users/Users/Component/SearchByUserId";

const ChatsFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Users" source="search" alwaysOn />
        <SearchByUserId source="userId" alwaysOn />
        <SelectInput label="Type" source="type" choices={ChatType} />
        <SelectInput label="Status" source="status" choices={ChatStatus} />
        <DateTimeInputMod label="Created Chat Date From" source="dateFrom" />
        <DateTimeInputMod label="Created Chat Date To" source="dateTo" />
        <DateTimeInputMod label="Last Activity Date From" source="lastActivityDateFrom" />
        <DateTimeInputMod label="Last Activity Date To" source="lastActivityDateTo" />
    </Filter>
);

export default ChatsFilter;