import React, {Fragment, useState} from 'react';
import {USER_STATUS_BLOCKED, USER_STATUS_DELETED} from "../constant/userConst";
import {Button, Confirm, useNotify, useRefresh, useTranslate, useUpdate} from "react-admin";
import BlockIcon from '@material-ui/icons//Block';
import { makeStyles } from '@material-ui/core/styles';
import users from "../../Users";

const useStyles = makeStyles({
    button: {
        '&:hover': {
            backgroundColor: 'rgba(244, 67, 54, 0.12)',
        },
        '& svg': {
            color: '#f44336',
        },
        '& span': {
            color: '#f44336',
        }
    },
});

const BlockButton = ({ record }) => {
    const refresh = useRefresh();
    const classes = useStyles();
    const notify = useNotify();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        blockOne();
        setOpen(false);
    };

    const [blockOne, { loading }] = useUpdate(
        `${users.name}`,
        (record ? record.id : null) + '/status',
        { status: USER_STATUS_BLOCKED },
        record,
        {
            onSuccess: () => { refresh() },
            onFailure: error => {
                notify(typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error', 'warning');
            },
        }
    );

    return record
        && record.status !== USER_STATUS_DELETED
        && record.status !== USER_STATUS_BLOCKED
        ? <Fragment>
            <Button label="Block" onClick={handleClick} className={classes.button}><BlockIcon/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('custom.users.block.title', record)}
                content={translate('custom.users.block.content', record)}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
        : '';
};

export default BlockButton;