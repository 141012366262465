import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import VerticalToolbar from "../../App/Component/Redesign/VerticalToolbar";
import ListMod from "../../App/Component/Redesign/ListMod";
import RelationshipsFilter from "./Component/GoalsFilter";
import relationships from "../Relationships";

export const RelationshipsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<RelationshipsFilter />}
          title="List of Relationships"
          basePath={`/${relationships.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <VerticalToolbar {...props}>
                <ShowButton />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);