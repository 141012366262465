import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import LanguagesFilter from "./Component/LanguagesFilter";
import VerticalToolbar from "../../App/Component/Redesign/VerticalToolbar";
import ListMod from "../../App/Component/Redesign/ListMod";
import EditButtonMod from "../../App/Component/Redesign/Button/EditButtonMod";
import LanguageDeleteButtonMod from "./Component/DeleteButtonMod";
import {ADMIN} from "../../App/Component/Constant/Roles";
import languages from "../../Pages/Languages";

export const LanguagesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<LanguagesFilter />}
          title="List of Languages"
          basePath={`/${languages.name}`}
          sort={{ field: 'priority', order: 'DESC' }}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <TextField label="Priority" source="priority" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[ADMIN]} />
                <LanguageDeleteButtonMod permissions={[ADMIN]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);