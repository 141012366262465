import React from 'react';
import {ChallengeStatus} from "../constant/challengeConst";
import StatusButton from "../../../../App/Component/Redesign/Button/StatusButton";
import {CONTENT_MANAGER} from "../../../../App/Component/Constant/Roles";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import challenges from '../../Challenges';

const ChangeStatusButton = ({permissions, ...props}) => {
    return <StatusButton {...props}
                         disabled={!HasPermissions([CONTENT_MANAGER])}
                         addLabel={true}
                         label="Status"
                         resource={challenges.name}
                         options={ChallengeStatus}
                         successNotify='custom.challenges.status.update.success' />
};

export default ChangeStatusButton;