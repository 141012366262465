import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    RichTextField, ReferenceField,
} from 'react-admin';
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";
import revealFeedbackOptions from "../FeedbackOptions";

export const RevealFeedbackShow = ( {permissions, ...props} ) => (
    <Show title=" Reveal Feedback View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            {HasPermissions([SUPPORT_ADMIN]) &&
                <UserField addLabel={true} label="User" source="accountId" />
            }
            <ReferenceField label="Option" source="optionId" reference={revealFeedbackOptions.name} link="show">
                <TextField source="text" />
            </ReferenceField>
            <RichTextField label="Question feedback" source="questionText" style={{overflowWrap: 'anywhere'}}/>
            <RichTextField label="Suggest feedback" source="suggestText" style={{overflowWrap: 'anywhere'}}/>
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
        </SimpleShowLayout>
    </Show>
);
