import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const DealBreakerShow = ( {permissions, ...props} ) => (
    <Show title="Deal Breaker View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Deal breaker name" source="dealBreakerTitle" />
            <TextField label="Daily life name" source="dailyLifeTitle" />
        </SimpleShowLayout>
    </Show>
);
