import React from 'react';
import sharingsQuestions from "./../"
import {QuestionPopularStatus} from "../constant/questionConst";
import PopularButton from "./PopularButton";

const ChangePopularButton = ({permissions, ...props}) => {
    return <PopularButton {...props}
                         addLabel={true}
                         label="Popular"
                         resource={sharingsQuestions.name}
                         options={QuestionPopularStatus}
                         successNotify='custom.sharings.popular.update.success'
                         permissions = {permissions}/>
};

export default ChangePopularButton;