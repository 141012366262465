import React from 'react';
import {SelectInput} from "react-admin";
import {validateRole} from "../validation/validation";
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";

const RoleEditInput = ({permissions, ...props}) => {
    if (props.record) {
        const isAdmin = props.record.role === ValueFromConst(AdminRolesList, 'Admin');

        return <SelectInput {...props} fullWidth disabled={isAdmin} label="Role" source="role" validate={validateRole} choices={AdminRolesList} />;
    }
};

export default RoleEditInput;
