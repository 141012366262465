import MenuBookIcon from "@material-ui/icons/MenuBook";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {RevealFeedbackOptionsList} from "./list";
import {RevealFeedbackOptionShow} from "./show";
import {RevealFeedbackOptionCreate} from "./create";
import {RevealFeedbackOptionEdit} from "./edit";

export default {
    name: "admin/reveals/feedback-options",
    list: RevealFeedbackOptionsList,
    show: RevealFeedbackOptionShow,
    create: HasPermissionsForAction(RevealFeedbackOptionCreate, [SUPPORT_ADMIN, CONTENT_MANAGER]),
    edit: HasPermissionsForAction(RevealFeedbackOptionEdit, [SUPPORT_ADMIN, CONTENT_MANAGER]),
    options: {
        label: 'Feedback Options',
        icon: MenuBookIcon
    }
};
