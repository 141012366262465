import React from 'react';
import {Chip} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { stringify } from 'qs';
import lifePurposes from "../../LifePurposes";

const ChipLifePurposesField = ({ record }) => {
    const color = record.count > 0 ? 'secondary' : 'default';
    let clickable = record.count > 0;

    return <Chip label={record.count}
                 disabled={!clickable}
                 clickable={clickable}
                 color={color}
                 component={Link}
                 to={{
                     pathname: `/${lifePurposes.name}`,
                     search: stringify({
                         filter: JSON.stringify({
                             category: record.id,
                         }),
                     }),
                 }}/>;
};

export default ChipLifePurposesField;