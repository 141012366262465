import React from 'react';

const styles = {
    container: {
        float: 'left',
    },
    image: {
        maxHeight: '500px',
        maxWeight: '500px',
        margin: '0.5rem',
    },
};

const ImageFieldMod = ({ record = {}, source, elStyle, globalUrl = false}) => {
    const defaultImage = process.env.PUBLIC_URL + '/default/not-found.png';
    const style = {...styles, ...elStyle};
    const titleValue = record['image_id'] ??  record['id'];
    const srcValue = globalUrl ? process.env.PUBLIC_UR + record[source] : record[source];

    if (!record[source]) {
        return <div style={style}>
            <img
                title={titleValue}
                alt={titleValue}
                src={defaultImage}
                style={style.image}
            />
        </div>;
    }

    return (
        <div style={style}>
            <img
                title={titleValue}
                alt={titleValue}
                src={srcValue}
                style={style.image}
            />
        </div>
    );
};

export default ImageFieldMod;
