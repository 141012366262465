import React from 'react';
import {FunctionField, ReferenceField} from "react-admin";
import {INVITE_TYPE_COMMUNITY, INVITE_TYPE_USER} from "../constant/userConst";
import UserField from "./UserField";
import communities from "../../../Communities/Communities";

const SourceEntityField = (props) => {
    if (props.record.invitationType && props.record.invitationType === INVITE_TYPE_COMMUNITY) {
        return <ReferenceField {...props} source="invitationCommunityId" reference={communities.name} link="show">
            <FunctionField render={record => `${record.title} #${record.id}`} />
        </ReferenceField>;
    } else if (props.record.invitationType && props.record.invitationType === INVITE_TYPE_USER) {
        return <UserField {...props} source="invitationAccountId" />;
    }

    return null;
}

export default SourceEntityField;