export const MESSAGE_TYPE_CHALLENGE = 19;
export const MESSAGE_TYPE_CHALLENGE_ANSWER = 20;
export const MESSAGE_TYPE_MESSAGE = 3;
export const MESSAGE_TYPE_CONNECTION_OFFER = 12;
export const MESSAGE_TYPE_CONNECTION_REJECT = 13;
export const MESSAGE_TYPE_CONNECTION_ACCEPT = 14;
export const MESSAGE_TYPE_EMPTY_TYPE = 101;
export const MESSAGE_TYPE_SYSTEM_MESSAGE = 102;

export const MessageType = [
    { id: MESSAGE_TYPE_CHALLENGE, name: 'Challenge' },
    { id: MESSAGE_TYPE_CHALLENGE_ANSWER, name: 'Challenge Answer' },
    { id: MESSAGE_TYPE_MESSAGE, name: 'Message' },

    { id: MESSAGE_TYPE_CONNECTION_OFFER, name: 'Connection Offer' },
    { id: MESSAGE_TYPE_CONNECTION_REJECT, name: 'Connection Reject' },
    { id: MESSAGE_TYPE_CONNECTION_ACCEPT, name: 'Connection Accept' },

    { id: MESSAGE_TYPE_EMPTY_TYPE, name: 'Empty' },
    { id: MESSAGE_TYPE_SYSTEM_MESSAGE, name: 'System Message' },
];

export const CONTENT_TYPE_TEXT = 1;
export const CONTENT_TYPE_IMAGE = 2;
export const CONTENT_TYPE_AUDIO = 3;
export const CONTENT_TYPE_DECLINE = 4;

export const MessageContentType = [
    { id: CONTENT_TYPE_TEXT, name: 'Text' },
    { id: CONTENT_TYPE_IMAGE, name: 'Image' },
    { id: CONTENT_TYPE_AUDIO, name: 'Audio' },
    { id: CONTENT_TYPE_DECLINE, name: 'Decline' }
];

export const IMAGE_TYPE_ORIGIN = 1;
export const IMAGE_TYPE_PREVIEW = 2;
export const IMAGE_TYPE_BLUR = 3;

export const MessageImageType = [
    { id: 1, name: 'origin' },
    { id: 2, name: 'preview' },
    { id: 3, name: 'blur' },
];