import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    CONTENT_TYPE_AUDIO,
    CONTENT_TYPE_IMAGE,
    CONTENT_TYPE_TEXT,
    IMAGE_TYPE_ORIGIN,
    IMAGE_TYPE_PREVIEW,
    MESSAGE_TYPE_CHALLENGE,
    MESSAGE_TYPE_CHALLENGE_ANSWER,
    MESSAGE_TYPE_MESSAGE,
} from "../constant/messageConst";
import LinkIcon from "@material-ui/icons/Link";
import ReactAudioPlayer from "react-audio-player";
import {NameFromId} from "../../../../App/Component/Handlers/ConstGeter";
import {ChallengeTypes} from "../../../Challenges/Challenges/constant/challengeConst";

const useStyles = makeStyles(theme => ({
    paragraph: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 1.3,
        marginBottom: theme.spacing(1.5),
    },
    image: {
        maxHeight: '200px',
        maxWidth: '200px',
        margin: '0.75rem',
    },
    notFoundFile: {
        maxHeight: '50px',
        maxWidth: '50px',
        margin: '0.75rem',
    },
    file: {
        margin: '10px',
    },
    fileIcon: {
        marginBottom: '-7px',
    },
    bold: {
        fontWeight: 600,
    },
    warning: {
        color: 'red',
    },
}));

export const MessageBody = ({message}) => {
    switch (true) {
        case message.type === MESSAGE_TYPE_MESSAGE:
            return <TextMessage message={message} />;
        case message.type === MESSAGE_TYPE_CHALLENGE:
            return <Challenge message={message} />;
        case message.type === MESSAGE_TYPE_CHALLENGE_ANSWER && message.contentType === CONTENT_TYPE_TEXT:
            return <ChallengeAnswer message={message} />;
        case message.type === MESSAGE_TYPE_CHALLENGE_ANSWER && message.contentType === CONTENT_TYPE_AUDIO:
            return <ChallengeAudioAnswer message={message} />;
        case message.type === MESSAGE_TYPE_CHALLENGE_ANSWER && message.contentType === CONTENT_TYPE_IMAGE:
            return <ChallengeImageAnswer message={message} />;
        default:
            return <NotExistMessage message={message}/>
    }
};

const Challenge = ({message}) => {
    const classes = useStyles();

    return <>
        <p className={`${classes.paragraph} ${classes.bold}`} key={`message_title_${message.id}`}>
            {`${NameFromId(ChallengeTypes, message.challengeType)} Challenge:`}
        </p>
        <p className={classes.paragraph} key={`message_${message.id}`}>
            {message.message}
        </p>
    </>;
}

const TextMessage = ({message}) => {
    const classes = useStyles();

    return message.message.split('\n')
        .map((paragraph, index) => (
            <p className={classes.paragraph} key={index}>
                {paragraph}
            </p>
        ));
}

const ChallengeAnswer = ({message}) => {
    const classes = useStyles();

    return <>
        <p className={`${classes.paragraph} ${classes.bold}`} key={`message_title_${message.id}`}>
            Challenge Answer:
        </p>
        {message.message.split('\n')
            .map((paragraph, index) => (
                <p className={classes.paragraph} key={index}>
                    {paragraph}
                </p>
            ))
        }
    </>
}

const ChallengeAudioAnswer = ({message}) => {
    const classes = useStyles();
    const files = message.files.filter(file => file.file);
    const defaultImage = process.env.PUBLIC_URL + '/default/not-found.png';

    return <>
        <p className={`${classes.paragraph} ${classes.bold}`} key={`message_title_${message.id}`}>
            Challenge Answer:
        </p>
        {files.length ?
            files.map((file) => (
                <>
                    <p className={classes.paragraph} key={`message_${message.id}`}>
                        Link to original file:
                        <a className={classes.file} href={file.file} target="_blank" rel="noopener noreferrer" title="Audio File">
                            <LinkIcon className={classes.fileIcon}/>
                        </a>
                    </p>
                    <div key={`message_${message.id}`}>
                        <ReactAudioPlayer src={file.file} controls/>
                    </div>
                </>
            ))
            :
            <div key={`message_${message.id}`}>
                <img className={classes.notFoundFile} title={message.id} alt={`message-file-${message.id}`} src={defaultImage} />
            </div>
        }
    </>
}

const ChallengeImageAnswer = ({message}) => {
    const classes = useStyles();
    const originFiles = message.files.filter(file => file.imageType === IMAGE_TYPE_ORIGIN && file.file);
    const previewFiles = message.files.filter(file => file.imageType === IMAGE_TYPE_PREVIEW && file.file);
    const defaultImage = process.env.PUBLIC_URL + '/default/not-found.png';

    return <>
        <p className={`${classes.paragraph} ${classes.bold}`} key={`message_title_${message.id}`}>
            Challenge Answer:
        </p>
        {originFiles.map((file) => (
            <p className={classes.paragraph} key={`message_${message.id}`}>
                Link to original file:
                <a className={classes.file} href={file.file} target="_blank" rel="noopener noreferrer" title="Image File">
                    <LinkIcon className={classes.fileIcon} />
                </a>
            </p>
        ))}
        {previewFiles.length ?
            previewFiles.map((file) => (
                <div key={`message_${message.id}`}>
                    <img className={classes.image} title={message.id} alt={`message-file-${message.id}`} src={file.file} />
                </div>
            ))
            :
            <div key={`message_${message.id}`}>
                <img className={classes.notFoundFile} title={message.id} alt={`message-file-${message.id}`} src={defaultImage} />
            </div>
        }
    </>
}

const NotExistMessage = ({message}) => {
    const classes = useStyles();
    const messageText = `Not exist message. Type: ${message.type}, Content Type: ${message.contentType} ⚠️`;
    return <p className={`${classes.paragraph} ${classes.warning}`} key={`message_${message.id}`}>{messageText}</p>
}