import React from 'react';
import {Filter, ReferenceInput, SearchInput, SelectInput} from 'react-admin';
import {BatchesStatuses} from "../constant/batchesConst";
import DateTimeInputMod from "../../../../App/Component/Redesign/Input/DateTimeInputMod";
import adminsShort from "../../../Users/AdminsShort";
import DateInputMod from "../../../../App/Component/Redesign/Input/DateInputMod";

const BatchFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn/>
        <SearchInput placeholder="Search by Question Text" source="searchText" alwaysOn/>
        <SelectInput label="Status" source="status" choices={BatchesStatuses}/>

        <DateInputMod label="Publish Date From" source="publishDateFrom"/>
        <DateInputMod label="Publish Date To" source="publishDateTo"/>

        <DateTimeInputMod label="Created Date From" source="createdDateFrom"/>
        <DateTimeInputMod label="Created Date To" source="createdDateTo"/>

        <DateTimeInputMod label="Edited Date From" source="editedDateFrom"/>
        <DateTimeInputMod label="Edited Date To" source="editedDateTo"/>

        <ReferenceInput label="Created By" source="createdBy" reference={adminsShort.name}>
            <SelectInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput label="Edited By" source="editedBy" reference={adminsShort.name}>
            <SelectInput optionText="name"/>
        </ReferenceInput>

    </Filter>
);

export default BatchFilter;