import React from 'react';
import {FunctionField, ReferenceField, Show, SimpleShowLayout, TextField,} from 'react-admin';
import countries from "../Countries/Countries";

export const CityShow = ( {permissions, ...props} ) => (
    <Show title="City View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="State name" source="stateName" />
            <ReferenceField label="Country" source="countryId" reference={countries.name} link="show">
                <FunctionField
                    render={record => `${record.name} ${record.flag}`}
                />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
