import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {QuestionsList} from "./list";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {QuestionEdit} from "./edit";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";

export default {
    name: "admin/sharings/questions",
    list: QuestionsList,
    edit: HasPermissionsForAction(QuestionEdit, [CONTENT_MANAGER]),
    options: {
        label: 'Questions',
        icon: HelpOutlineIcon,
    }
};