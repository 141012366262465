import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {LifePurposesList} from "./list";
import {LifePurposeShow} from "./show";
import {LifePurposeCreate} from "./create";
import {LifePurposeEdit} from "./edit";

export default {
    name: "admin/attributes/life-purposes",
    list: LifePurposesList,
    show: LifePurposeShow,
    create: HasPermissionsForAction(LifePurposeCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(LifePurposeEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Life Purposes',
        icon: AssignmentTurnedInIcon,
    }
};