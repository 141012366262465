import React from "react";
import CardChart from "../../Component/CardChart";

export const GoalsChart = (props) => {
    const { data } = props;
    const id = 'goalsChart';
    const options = {
        type: 'bar',
        title: 'Goals',
        legend: false,
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        data: [],
        labels: [],
    };

    for (const [key, value] of Object.entries(data)) {
        options.labels.push(key);
        options.data.push(value);
    }

    return (
        <CardChart id={id} {... options} />
    );
};