export const BatchesStatuses = [
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Scheduled' },
    { id: 3, name: 'Published' },
    { id: 4, name: 'Deactivated' },
];

export const BatchesStatusesForCreating = [
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Scheduled' }
];