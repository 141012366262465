import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import CountriesFilter from "./Component/CountriesFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import MultilineTextField from "../../../App/Component/Redesign/Field/MultilineTextField";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import configs from "../../../Pages/Configs/Configs";

export const ConfigsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<CountriesFilter />}
          title="List of Configs"
          basePath={`/${configs.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="showName" />
            <MultilineTextField label="Value" source="value" />
            <DateFieldMod label="Updated At" source="updatedAt" showTime />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);