import ReceiptIcon from "@material-ui/icons/Receipt";
import {SUPPORT_ADMIN, VIEWER} from "../../../App/Component/Constant/Roles";
import {ReportsList} from "./list";
import {ReportShow} from "./show";

export default {
    name: "admin/reports",
    list: ReportsList,
    show: ReportShow,
    permissions: [SUPPORT_ADMIN, VIEWER],
    options: {
        label: 'Feedbacks',
        icon: ReceiptIcon
    }
};