import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  custom: {
    status: {
      update: {
        success: 'Status updated successfully',
      },
    },
    delete: {
      title: 'Delete #%{id}',
      content: 'Are you sure you want to delete this item?',
    },
    image: {
      upload: {
        success: '%{label} uploaded success',
      },
    },
    feedbacks: {
      status: {
        update: {
          success: 'Support Request status updated successfully',
        },
      },
    },
    reports: {
      status: {
        update: {
          success: 'Feedback status updated successfully',
        },
      },
    },
    challenges: {
      status: {
        update: {
          success: 'Challenge status updated successfully',
        },
      },
    },
    sharings: {
      status: {
        update: {
          success: 'Status updated successfully',
        },
      },
      popular: {
        update: {
          success: 'Popular updated successfully',
        },
      },
      onboarding: {
        update: {
          success: 'Onboarding updated successfully',
        },
      },
    },
    attributes: {
      interestCategory: {
        delete: {
          title: 'Delete Interest Category #%{id}',
          content: "Are you sure you want to delete Category '%{title}' and all %{count} Interests in it ?",
        }
      },
      lifePurposeCategory: {
        delete: {
          title: 'Delete Life Purpose Category #%{id}',
          content: "Are you sure you want to delete Category '%{title}' and all %{count} Life Purposes in it ?",
        }
      }
    },
    export: {
      fail: 'Export error, Please contact your system administrator',
    },
    users: {
      block: {
          title: 'Block user #%{id}',
          content: 'Are you sure you want to block this user ?',
      }
    }
  },
};

export default customEnglishMessages;