import React from 'react';
import {TextInput} from 'react-admin';
import {DEFAULT_LANGUAGE} from "../constant/languageConst";

const NameInput = (props) => {
    const disabled = props.record && props.record.title === DEFAULT_LANGUAGE;

    return <TextInput disabled={disabled} {...props} />
};

export default NameInput;