import React from 'react';
import {
    Datagrid,
    TextField,
    SelectField,
    ShowButton,
    ReferenceField,
    FunctionField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import ActivateButton from "./Component/ActivateButton";
import DeactivateButton from "./Component/DeactivateButton";
import {AdminRolesList} from "../../../App/Component/Constant/Roles";
import AdminsFilter from "./Component/AdminsFilter";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import countries from "../../Countries/Countries";
import admins from '../Admins';

export const AdminsList = ({permissions, ...props}) => (
    <ListMod {...props}
        filters={<AdminsFilter />}
        title="List of Internal Team"
        basePath={`/${admins.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Last Name" source="lastName" />
            <TextField label="Email" source="email" />
            <ReferenceField label="Country" source="countryId" reference={countries.name} link="show" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="Phone" render={record => record.phone ? `${record.phoneCode}${record.phone}` : ``} sortable={false} />
            <SelectField label="Role" source="role" sortable={false} choices={AdminRolesList} />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod />
                <ActivateButton />
                <DeactivateButton />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);