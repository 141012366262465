import React from 'react';
import DeleteButtonMod from "../../../App/Component/Redesign/Button/DeleteButtonMod";
import {DEFAULT_LANGUAGE} from "../constant/languageConst";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";

const LanguageDeleteButtonMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    if (!props.record || (props.record && props.record.title === DEFAULT_LANGUAGE)) {
        return null;
    }

    return access && <DeleteButtonMod {...props} />
};

export default LanguageDeleteButtonMod;