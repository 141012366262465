import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout, ReferenceField,
} from 'react-admin';
import lifePurposesCategory from "../LifePurposesCategory";

export const LifePurposeShow = ( {permissions, ...props} ) => (
    <Show title="Life Purpose View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ReferenceField label="Category" source="categoryId" reference={lifePurposesCategory.name} sortable={false} link="show">
                <TextField source="title" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
