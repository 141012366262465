import {fetchUtils} from 'react-admin';
import { stringify } from 'qs';
import inMemoryJWT from '../Auth/inMemoryJwt';
import {DateTimeWithOutTimeZone} from "../Component/Handlers/DateTimeFormat";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const httpClient = (url, options = {}, blob = false) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }

    // wait when token already refreshing
    return inMemoryJWT.waitForTokenRefresh().then(() => {
        // if token valid send request
        if (inMemoryJWT.getToken() && !inMemoryJWT.isExpired()) {
            options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);

            return fetchUtils.fetchJson(url, options);
        } else {
            // if token invalid send refresh token request
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                // if token refreshed success change token for request
                if (gotFreshToken) {
                    options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
                }

                return fetchUtils.fetchJson(url, options);
            });
        }
    });
};

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        if(data.hasOwnProperty('rawFile')) {
            buildFormData(formData, data['rawFile'], parentKey)
        } else {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        }
    } else if (data && data instanceof Date) {
        formData.append(parentKey, DateTimeWithOutTimeZone(data));
    } else {
        formData.append(parentKey, data);
    }
}

function buildFilterData(filterData) {
    if (filterData) {
        Object.keys(filterData).forEach(key => {
            if (filterData[key] && filterData[key] instanceof Date) {
                filterData[key] = DateTimeWithOutTimeZone(filterData[key]);
            } else if (key.includes('dateFrom') || key.includes('dateTo') || key.includes('DateFrom') || key.includes('DateTo')) {
                filterData[key] = DateTimeWithOutTimeZone(filterData[key]);
            }
        });
    }

    return filterData;
}

export default {
    getList: (resource, params) => {
        const query = {
            sort: {
                column: params.sort.field,
                direction: params.sort.order
            },
            limit: params.pagination.perPage,
            page: params.pagination.page,
            filter: buildFilterData(params.filter),
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data.items !== undefined ? json.data.items : json.data,
            total: json.data.total !== undefined  ? json.data.total :  json.data.length,
        }));
    },

    getStatistic: (resource, params) => {
        const query = {filter: buildFilterData(params.filter)};
        const url =`${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data
        }));
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data
        }));
    },

    getMany: (resource, params) => {
        const query = {filter: {id: params.ids}};
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => ({
            data: json.data.items !== undefined ? json.data.items : json.data
        }));
    },

    getManyReference: (resource, params) => {
        const query = {
            sort: {
                column: params.sort.field,
                direction: params.sort.order
            },
            limit: params.pagination.perPage,
            page: params.pagination.page,
            filter: buildFilterData({
                ...params.filter,
                [params.target]: params.id
            }),
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data.items !== undefined ? json.data.items : json.data,
            total: json.data.total !== undefined  ? json.data.total :  json.data.length,
        }));
    },

    update: (resource, params) => {
        const method = params.data.updateMethod === 'POST' ? params.data.updateMethod : 'PUT';
        let form = new FormData();
        buildFormData(form, params.data);
        const body = method === 'POST' ? form : JSON.stringify(params.data);

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: method,
            body: body,
        })
        .then(({ json }) => ({
            data: { ...params.previousData, ...params.data, ...json.data} //hack, response must have updated `data` object
        }))
        .catch(error => {
            if (error.body.errors) {
                const key = Object.keys(error.body.errors)[0];
                error.message = `${error.body.errors[key]}`;
            }

            return Promise.reject(error); // rethrow it
        });
    },

    updateMany: (resource, params) => {
        const query = {filter: JSON.stringify({id: params.ids})};

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        })
        .then(({ json }) => ({
            data: { ...params.data, ...json.data}
        }));

    },

    create: (resource, params) => {
        let form = new FormData();
        buildFormData(form, params.data);

        return  httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: form,
        }).then(response => {
            return response;
        }).then(({ json }) => ({
            data: { id: null, ...params.data, ...json.data }, //hack, response must have `id` parameter
        })).catch(error => {
            if (error.body.errors) {
                const key = Object.keys(error.body.errors)[0];
                error.message = `${error.body.errors[key]}`;
            }

            return Promise.reject(error); // rethrow it
        });
    },

    delete: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({
            data: json
        })).catch(error => {
            if (error.body.errors) {
                const key = Object.keys(error.body.errors)[0];
                error.message = `${error.body.errors[key]}`;
            }

            return Promise.reject(error); // rethrow it
        });
    },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };

        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};