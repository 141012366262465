export const IOS_APP_VERSION = 1;
export const ANDROID_APP_VERSION = 2;
export const IOS_LINK = 3;
export const SECRET_CODE_SMS_TEXT = 4;
export const PREMIUM_LIGHT_CHAT_COUNT = 5;
export const SUPPORT_EMAIL = 6;
export const NOREPLY_EMAIL = 7;
export const DEEP_LINK = 8;
export const TEST_AUTH_NUMBERS = 9;
export const TEST_AUTH_SECRET_KEY = 10;
export const REVEAL_TEXT = 11;
export const REVEAL_COUNT = 12;