import ChilliIcon from "../../../../App/Component/Redesign/ChilliIcon";
import React from 'react'

export const QuestionCategories = [
    { id: 1, name: <ChilliIcon/>},
    { id: 2, name: [<ChilliIcon/>,<ChilliIcon/>] },
    { id: 3, name: [<ChilliIcon/>,<ChilliIcon/>, <ChilliIcon/>] },
];
export const QuestionStatuses = [
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Scheduled' },
    { id: 3, name: 'Published' },
    { id: 4, name: 'Deactivated' },
    { id: 5, name: 'Active' },
];

export const QuestionStatusesForCreate = [
    { id: 1, name: 'Draft' },
    { id: 5, name: 'Active' }
];

export const QuestionPopularStatus = [
    { id: 0, name: 'Not Popular' },
    { id: 1, name: 'Popular' }
];
export const QuestionOnboardingStatus = [
    { id: 0, name: 'Not Onboarding' },
    { id: 1, name: 'Onboarding' }
];