import React from 'react';
import sharingsQuestions from "./../"
import {QuestionOnboardingStatus} from "../constant/questionConst";
import OnboardingButton from "./OnboardingButton";

const ChangeOnboardingButton = ({permissions, ...props}) => {
    return <OnboardingButton {...props}
                         addLabel={true}
                         label="Onboarding"
                         resource={sharingsQuestions.name}
                         options={QuestionOnboardingStatus}
                         successNotify='custom.sharings.onboarding.update.success'
                         permissions = {permissions}/>
};

export default ChangeOnboardingButton;