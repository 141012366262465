import React from 'react';
import {Create, SelectInput, SimpleForm, TextInput} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName, validateStatus, validateText} from "./validation/validation";
import {BatchesStatusesForCreating} from "./constant/batchesConst";
import DateMod from "./Component/DateMod";


export const BatchCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar/>}>
            <TextInput multiline fullWidth label="Name" source="name" validate={validateName}/>

            <SelectInput fullWidth label="Status" source="status" choices={BatchesStatusesForCreating} initialValue={1}
                         validate={validateStatus}/>

            <TextInput multiline fullWidth label="First Question (1 chilli)" source="questionOneChilli"
                       validate={validateText}/>
            <TextInput multiline fullWidth label="Second Question (2 chillies)" source="questionTwoChillies"
                       validate={validateText}/>
            <TextInput multiline fullWidth label="Third Question (3 chillies)" source="questionThreeChillies"
                       validate={validateText}/>


            <DateMod/>
        </SimpleForm>
    </Create>
);