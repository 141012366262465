import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    RichTextField,
} from 'react-admin';
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";

export const NounShow = ( {permissions, ...props} ) => (
    <Show title="Noun View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <RichTextField label="Description" source="description" />
            <ImageFieldMod addLabel={true} label="Image" source="image" sortable={false} />
        </SimpleShowLayout>
    </Show>
);
