import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {DateInput} from "@shinabr2/react-admin-date-inputs";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {InputAdornment} from "@material-ui/core";

const customStyles = makeStyles({
    element: {
        marginBottom: '-15px'
    },
    dateInput: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginTop: '0 !important',
        "&:focused": {
            backgroundColor: 'rgba(0, 0, 0, 0.13)',
        },
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.13)',
        },
    },
    dateInputLabel: {
        "&.shrink": {
            transform: 'translate(0, 12px) scale(0.75)',
        },
    },
});

const DateInputMod = props => {
    const classes = customStyles();

    return <DateInput providerOptions={{ utils: DateFnsUtils }}
                      label={props.label}
                      source={props.source}
                      className={classes.element}
                      options={{
                          size: 'small',
                          fullWidth: !!props.fullWidth,
                          InputProps: {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <DateRangeIcon />
                                  </InputAdornment>
                              ),
                              classes: {
                                  root: classes.dateInput,
                                  focused: "focused",
                              },
                          },
                          InputLabelProps: {
                              classes: {
                                  root: classes.dateInputLabel,
                                  shrink: "shrink"
                              }
                          },
                          autoOk: true,
                          clearable: true,
                          clearLabel: 'CLEAR',
                          cancelLabel: 'CANCEL',
                          disablePast: !!props.disablePast,
                          format: 'dd/MM/yyyy',
                      }} />
};

export default DateInputMod;