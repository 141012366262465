import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';
import authProvider from "../../../App/Auth/authProvider";

const useStyles = makeStyles(theme => ({
    main: {
        backgroundColor: '#0c2461',
        backgroundImage: 'url(login-bg.svg)',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '12em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    inputToken: {
        display: 'none',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const { Form } = withTypes();

const LoginForm = props => {
    const { redirectTo } = props;
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [token, setToken] = useState(null);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);

    const validateSend = (values) => {
        const errors = {
            username: undefined,
            password: undefined
        };

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }

        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }

        return errors;
    };

    const validate = (values) => {
        const errors = {
            code: undefined
        };

        if (!values.code) {
            errors.code = translate('ra.validation.required');
        }

        return errors;
    };

    const submitSend = values => {
        setLoading(true);
        authProvider.sendSms(values)
            .then((data) => {
                setLoading(false);
                setSend(true);
                setToken(data.data);
            })
            .catch(error => {
                setLoading(false);
                setSend(false);
                setToken(null);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'warning'
                );
            });
    }

    const submit = values => {
        setLoading(true);
        values.token = token;
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            });
    };

    const back = () => {
        setLoading(false);
        setSend(false);
        setToken(null);
    };

    if (!send) {
        return (
            <Form
                onSubmit={submitSend}
                validate={validateSend}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <LockIcon />
                                    </Avatar>
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            id="username"
                                            name="username"
                                            component={renderInput}
                                            label={'Email'}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            id="password"
                                            name="password"
                                            component={renderInput}
                                            label={'Password'}
                                            type="password"
                                            disabled={loading}
                                            autoComplete="current-password"
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {'Send'}
                                    </Button>
                                </CardActions>
                            </Card>
                            <Notification />
                        </div>
                    </form>
                )}
            />
        );
    }

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        id="code"
                                        name="code"
                                        component={renderInput}
                                        label={'Confirmation Code'}
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                            </CardActions>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="default"
                                    onClick={back}
                                    fullWidth
                                >
                                    {'Back'}
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;