import ChilliIcon from "../../../../App/Component/Redesign/ChilliIcon";
import React from 'react';
import {useRecordContext} from "react-admin";

const IconField = (props) => {
    const record = useRecordContext(props);
    if (record){
        return [...Array(record.category)].map(() => <ChilliIcon/>);
    }
    return '';
}

export default IconField;