import React from 'react';
import {
    Filter,
    SearchInput,
} from 'react-admin';

const ConfigsFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
    </Filter>
);

export default ConfigsFilter;