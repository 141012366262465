import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';

const BackButton = ({ title = "Cancel", history: { goBack } }) => (
    <Button variant='outlined' color='secondary' style={{ marginLeft: '1rem' }} onClick={goBack}>
        {title}
    </Button>
)

export default withRouter(BackButton);