import {SUPPORT_ADMIN} from "../../App/Component/Constant/Roles";
import {GoalsList} from "./list";
import {GoalShow} from "./show";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

export default {
    name: "admin/goals",
    list: GoalsList,
    show: GoalShow,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Goals',
        icon: PersonAddIcon
    }
};