import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    SimpleShowLayout,
    RichTextField,
    ReferenceField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";
import RevealFeedbacksFilter from "./Component/FeedbacksFilter";
import revealFeedbackOptions from "../../../Pages/Reveals/FeedbackOptions";
import revealFeedbacks from "../Feedbacks";

export const RevealFeedbacksList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<RevealFeedbacksFilter />}
          title="List of Reveal Feedbacks"
          basePath={`/${revealFeedbacks.name}`}
    >
        <RevealFeedbackDataGrid />
    </ListMod>
);

const RevealFeedbackDataGrid = props => {
    if (!HasPermissions([SUPPORT_ADMIN])) {
        return <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" />
            <ReferenceField label="Option" source="optionId" reference={revealFeedbackOptions.name} sortable={false} link="show">
                <TextField source="text" />
            </ReferenceField>
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
            <ShowButton />
        </Datagrid>
    }

    return <Datagrid expand={<PostShow />}>
        <TextField label="ID" source="id" />
        <UserField label="User" source="accountId" sortable={false} />
        <ReferenceField label="Option" source="optionId" reference={revealFeedbackOptions.name} sortable={false} link="show">
            <TextField source="text" />
        </ReferenceField>
        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
        <ShowButton />
    </Datagrid>
}

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField label="Question text" source="questionText" style={{overflowWrap: 'anywhere'}}/>
        <RichTextField label="Suggest text" source="suggestText" style={{overflowWrap: 'anywhere'}}/>
    </SimpleShowLayout>
);