import React from 'react';
import {
    Filter,
    SearchInput, SelectInput,
} from 'react-admin';
import {StatusTextList} from "../../../../App/Component/Constant/Status";

const RevealFeedbackOptionsFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Text" source="search" alwaysOn />
        <SelectInput label="Status" source="status" choices={StatusTextList} />
    </Filter>
);

export default RevealFeedbackOptionsFilter;