import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const EndSessionCommentShow = ( {permissions, ...props} ) => (
    <Show title="Chat Ended Type View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" />
        </SimpleShowLayout>
    </Show>
);
