import React from "react";
const ChilliIcon = () => {
        return (
            <svg width="16" height="28" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.37587 6.40004C8.5797 6.27043 8.84079 6.27043 9.04458 6.40004C9.87348 6.92722 10.9157 6.94732 11.7646 6.45254L11.9598 6.33877C11.6947 5.05153 10.6705 4.03541 9.37344 3.77308C9.54338 2.69375 10.2079 1.74079 11.1926 1.20767L11.2891 1.15542C11.5896 0.992669 11.7003 0.618935 11.5364 0.320669C11.3724 0.0224038 10.9959 -0.087436 10.6954 0.0752319L10.599 0.127486C9.21936 0.874462 8.30637 2.23339 8.12457 3.75934C6.79109 3.99632 5.73105 5.0261 5.4607 6.33877L5.65593 6.45254C6.50479 6.94732 7.54702 6.92722 8.37587 6.40004Z" fill="red"/>
                <path d="M8.71058 7.62635C8.16397 7.90272 7.56923 8.04139 6.97383 8.04139C6.43193 8.04139 5.88958 7.9265 5.38211 7.69616C5.24976 11.9266 3.93385 14.9552 2.81998 16.7818C1.54538 18.872 0.251895 19.8893 0.240078 19.8985C0.0306746 20.0597 -0.0523759 20.3351 0.033443 20.584C0.119262 20.8328 0.354861 21 0.619838 21C3.22932 21 5.46111 20.2483 7.25323 18.7659C8.67529 17.5895 9.81292 15.9593 10.6345 13.9205C11.6518 11.3959 11.9261 8.90136 12 7.71322C10.9441 8.17859 9.74185 8.14786 8.71058 7.62635V7.62635Z" fill="red"/>
            </svg>
        )
};

export default ChilliIcon