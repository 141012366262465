import React from 'react';
import {
    TextInput,
    SelectInput,
    Create,
    SimpleForm,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateGroup, validateText, validateType} from "./validation/validation";
import {ChallengeGroups, ChallengeTypes} from "./constant/challengeConst";

export const ChallengeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput multiline fullWidth label="Text" source="text" validate={[validateText]} />
            <SelectInput fullWidth label="Type" source="type" choices={ChallengeTypes} validate={validateType} />
            <SelectInput fullWidth label="Group" source="group" choices={ChallengeGroups} validate={validateGroup} />
        </SimpleForm>
    </Create>
);