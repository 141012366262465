import React from 'react';
import {Datagrid, FunctionField, ReferenceField, ShowButton, TextField,} from 'react-admin';
import cities from "./index";
import ListMod from "../../App/Component/Redesign/ListMod";
import CitiesFilter from "./Component/CitiesFilter";
import countries from "../Countries/Countries";
import VerticalToolbar from "../../App/Component/Redesign/VerticalToolbar";

export const CitiesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<CitiesFilter />}
          title="List of Cities"
          basePath={`/${cities.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" sortable={false} />
            <TextField label="State name" source="stateName" sortable={false} />
            <ReferenceField label="Country" source="countryId" reference={countries.name} link="show" sortable={false} >
                <FunctionField
                    render={record => `${record.name} ${record.flag}`}
                />
            </ReferenceField>
            <VerticalToolbar {...props}>
                <ShowButton />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);