import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import LifePurposesCategoryFilter from "./Component/LifePurposesCategoryFilter";
import ChipLifePurposesField from "./Component/ChipLifePurposesField";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import DeleteButtonWithConfirm from "../../../App/Component/Redesign/Button/DeleteButtonWithConfirm";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import lifePurposesCategory from "./index";

export const LifePurposesCategoryList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<LifePurposesCategoryFilter />}
          title="List of Life Purposes Categories"
          basePath={`/${lifePurposesCategory.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ChipLifePurposesField label="Count Life Purposes" source="count" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
                <DeleteButtonWithConfirm
                    {...props}
                    permissions={[CONTENT_MANAGER]}
                    route={lifePurposesCategory.name}
                    deleteContent="custom.attributes.lifePurposeCategory.delete.content"
                    deleteTitle="custom.attributes.lifePurposeCategory.delete.title"
                />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);