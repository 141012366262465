import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    SelectField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import RevealFeedbackOptionsDeleteButtonMod from "./Component/DeleteButtonMod";
import RevealFeedbackOptionsFilter from "./Component/RevealFeedbackOptionsFilter";
import {StatusTextList} from "../../../App/Component/Constant/Status";
import {ValueFromConst} from "../../../App/Component/Handlers/ConstGeter";
import revealFeedbackOptions from "../FeedbackOptions";

export const RevealFeedbackOptionsList = ({permissions, ...props}) => (
    <ListMod {...props}
             filterDefaultValues={{status: ValueFromConst(StatusTextList, 'Active')}}
             filters={<RevealFeedbackOptionsFilter />}
             title="List of Reveal Feedback Options"
             basePath={`/${revealFeedbackOptions.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" sortable={false} />
            <SelectField label="Status" source="status" sortable={false} choices={StatusTextList} />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
                <RevealFeedbackOptionsDeleteButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);