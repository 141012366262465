import React from "react";
import {Resource} from "react-admin";
import {HasPermissions} from "../Handlers/HasPermissions";

export const ResourceMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    return access && <Resource {...props} />;
}

export default ResourceMod;