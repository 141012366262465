import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import InterestsCategoryFilter from "./Component/InterestsCategoryFilter";
import ChipInterestsField from "./Component/ChipInterestsField";
import ImageFieldMod from "../../../App/Component/Redesign/Field/ImageFieldMod";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import DeleteButtonWithConfirm from "../../../App/Component/Redesign/Button/DeleteButtonWithConfirm";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import interestsCategory from "./index";

export const InterestsCategoryList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<InterestsCategoryFilter />}
          title="List of Interests Categories"
          basePath={`/${interestsCategory.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ImageFieldMod source="image" sortable={false} elStyle={{image: {maxHeight: '100px', maxWidth: '100px'}}} />
            <ChipInterestsField label="Count Interests" source="count" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
                <DeleteButtonWithConfirm
                    {...props}
                    permissions={[CONTENT_MANAGER]}
                    route={interestsCategory.name}
                    deleteContent="custom.attributes.interestCategory.delete.content"
                    deleteTitle="custom.attributes.interestCategory.delete.title"
                />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);