import React from 'react';
import {PasswordInput} from "react-admin";
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import {validatePasswordEdit, } from "../validation/validation";
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";

const PasswordEditInput = ({permissions, ...props}) => {
    if (props.record && props.record.role !== ValueFromConst(AdminRolesList, 'Admin')) {
        return <PasswordInput {...props} fullWidth source="password" validate={validatePasswordEdit} />;
    }

    return null;
};

export default PasswordEditInput;
