import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    ReferenceField,
} from 'react-admin';
import interestsCategory from "../InterestsCategory";

export const InterestShow = ( {permissions, ...props} ) => (
    <Show title="Interest View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ReferenceField label="Category" source="categoryId" reference={interestsCategory.name} sortable={false} link="show">
                <TextField source="title" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
