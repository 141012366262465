import WcIcon from "@material-ui/icons/Wc";
import {SUPPORT_ADMIN} from "../../App/Component/Constant/Roles";
import {IdentityGendersList} from "./list";
import {IdentityGenderShow} from "./show";

export default {
    name: "admin/identity-genders",
    list: IdentityGendersList,
    show: IdentityGenderShow,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Identity Genders',
        icon: WcIcon
    }
};