import DateFnsUtils from "@date-io/date-fns";
import {InputAdornment} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {DateInput} from "@shinabr2/react-admin-date-inputs";
import React from "react";

const validateDate = (value) => {
    if (!value) {
        return 'Required';
    }
    return undefined;
};


export const dateCustomParser = v => {
    const regexp = /(\d{4})-(\d{2})-(\d{2})/
    var match = regexp.exec(v);
    if (match === null) return;

    var year = match[1];
    var month = match[2];
    var day = match[3];
    return [year, month, day].join("-");
};

const DateMod = (props) => (
    <DateInput
        providerOptions={{utils: DateFnsUtils}}
        label="Publish Date"
        parse={dateCustomParser}
        source="publishDate"
        validate = {validateDate}
        options={{
            size: 'small',
            InputProps: {
                endAdornment: (
                    <InputAdornment position="end">
                        <DateRangeIcon/>
                    </InputAdornment>
                ),
            },
            InputLabelProps: {
                classes: {
                    shrink: "shrink"
                }
            },
            autoOk: true,
            variant: "inline",
            inputVariant: "outlined",
            disablePast: true,
            format: 'dd/MM/yyyy',
            clearLabel: 'CLEAR',
            cancelLabel: 'CANCEL',
        }}
    />
)

export default DateMod;