import React from 'react';

const PreviewImageField = ({ record, source, error }) => {
    if (error) return null;

    if (record.image) {
        record = record.image;
    } else if (record.key) {
        record = record.key;
    } else if (record[source]) {
        record = record[source].origin ? record[source].origin : record[source];
    } else {
        record = record.origin ? record.origin : record;
    }

    return (<img width={150} src={record.origin ? record.origin : record} alt="Preview"/>);
};

export default PreviewImageField;
