import React, {useCallback} from 'react';
import {Button, downloadCSV, useDataProvider, useNotify} from "react-admin";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import jsonExport from 'jsonexport/dist';
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {MAX_AGE} from "../../Users/Users/constant/userConst";
import matching from "../Matching";

const ExportMatchingButton = ({ filterValues = {}, permissions = null }) => {
    const access = permissions ? HasPermissions(permissions) : true;

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchMatching = useCallback(async () => {
        const params = {};
        params.filter = filterValues;
        params.sort = {order: 'ASC', field: 'name'};
        params.pagination = {perPage: 5000, page: 1};

        return await dataProvider.getList(`${matching.name}/export`, params);
    }, [dataProvider, filterValues]);

    const handleMenuItemClick = () => {
        ExportMatching();
    };

    function ExportMatching() {
        fetchMatching()
            .then(({ data }) => {
                const postsForExport = data.map(user => {
                    const postForExport = {};

                    postForExport.id = user.id;
                    postForExport.name = user.avatarName ? `${user.avatarName}` : "";
                    postForExport.gender = user.gender ?? "";
                    postForExport.age = user.age ?? "";
                    postForExport.communities = user.communities ? `${user.communities}` : "None";
                    postForExport.filterMinAge = user.filterMinAge ?? "";
                    postForExport.filterMaxAge = user.filterMaxAge !== MAX_AGE ? user.filterMaxAge : "Max";
                    postForExport.filterDistance = user.filterDistance ? `${user.filterDistance}` : "Everywhere";
                    postForExport.filterGenders = user.filterGenders ? `${user.filterGenders}` : "All";
                    postForExport.filterGoals = user.filterGoals ? `${user.filterGoals}` : "All";
                    postForExport.filterCommunities = user.filterCommunities ? `${user.filterCommunities}` : "All";
                    postForExport.dealBreakers = user.dealBreakers ? `${user.dealBreakers}` : "None";
                    postForExport.dailyLife = user.dailyLife ? `${user.dailyLife}` : "None";
                    postForExport.matches = user.countChats ?? 0;

                    return postForExport;
                });

                jsonExport(postsForExport, {
                    rename: [
                        'ID',
                        'Name',
                        'Gender',
                        'Age (years)',
                        'Communities',
                        'Filter Min Age (years)',
                        'Filter Max Age (years)',
                        'Filter Distance (km)',
                        'Filter Genders',
                        'Filter Goals',
                        'Filter Communities',
                        'Deal Breakers',
                        'A Bit About Me',
                        'Count Matches'
                    ],
                    rowDelimiter: ';',
                }, (err, csv) => {
                    downloadCSV(csv, `matching_${Date.now()}`);
                });
            })
            .catch(error => {
                notify('custom.export.fail', 'warning', {});
                console.log(error);
            })
    }

    return access && <Button label="Export" onClick={(event) => {handleMenuItemClick()}}><VerticalAlignBottomIcon/></Button>;
};

export default ExportMatchingButton;