import { stringify } from 'qs';
import decodeJwt from 'jwt-decode';
import inMemoryJWT from './inMemoryJwt';
import { HasPermissions } from "../Component/Handlers/HasPermissions";
import {ADMIN, CONTENT_MANAGER, SUPPORT_ADMIN, VIEWER} from "../Component/Constant/Roles";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const getTokenEndpoint = apiUrl + `/admin/token/create`;
const sendSmsEndpoint = apiUrl + `/admin/token/send`;

export default {
    sendSms: ({ username, password }) => {
        const request = new Request(sendSmsEndpoint, {
            method: 'POST',
            body: stringify({
                email: username,
                password: password
            }),
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        });
        return fetch(request)
            .then((response) => {
                if (
                    response.status < 200 ||
                    (response.status >= 300 && response.status < 400) ||
                    (response.status > 500)
                ) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (!data.success && data.errors) {
                    throw new Error(data.errors[Object.keys(data.errors)[0]]);
                }
                return data;
            })
    },
    login: ({ token, code}) => {
        const request = new Request(getTokenEndpoint, {
            method: 'POST',
            body: stringify({
                token: token,
                code: code
            }),
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        });

        return fetch(request)
            .then((response) => {
                if (
                    response.status < 200 ||
                    (response.status >= 300 && response.status < 400) ||
                    (response.status > 500)
                ) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (!data.success && data.errors) {
                    throw new Error(data.errors[Object.keys(data.errors)[0]]);
                }

                if (data.success) {
                    const decodedToken = decodeJwt(data.data.token);
                    const roles = decodedToken.roles[0];

                    if (!HasPermissions([ADMIN, SUPPORT_ADMIN, CONTENT_MANAGER, VIEWER], roles)) {
                        throw new Error("You don`t have permission!");
                    }

                    return data;
                }
            })
            .then((data) => {
                localStorage.clear();
                return data;
            })
            .then(({ data }) => inMemoryJWT.setToken(data.token, data.refreshToken));
    },
    // called when the user clicks on the logout button
    logout: () => {
        inMemoryJWT.clearToken();

        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {

            inMemoryJWT.clearToken();
            return Promise.reject();
        }

        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() && HasPermissions([ADMIN, SUPPORT_ADMIN, CONTENT_MANAGER, VIEWER])
                ? Promise.resolve(inMemoryJWT.getRoles())
                : Promise.reject();
        });
    },
};