import React from 'react';
import {
    AutocompleteInput,
    Edit,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {
    validateLastName,
    validateName,
    validatePhone,
    validatePhoneCode
} from "./validation/validation";
import RoleEditInput from "./Component/RoleEditInput";
import EmailEditInput from "./Component/EmailEditInput";
import PasswordEditInput from "./Component/PasswordEditInput";
import RepeatPasswordEditInput from "./Component/RepeatPasswordEditInput";
import countries from "../../Countries/Countries";

export const optionRendererPhone = record => {
    return record.id ? `${record.name} (${record.dialCode})` : '';
}

export const AdminEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="name" validate={validateName} />
            <TextInput fullWidth label="Last Name" source="lastName" validate={validateLastName} />
            <EmailEditInput fullWidth />
            <ReferenceInput fullWidth
                            label="Phone code"
                            source="countryId"
                            reference={countries.name}
                            filterToQuery={searchText => ({ name: searchText })}
                            allowEmpty={true}
                            validate={validatePhoneCode}>
                <AutocompleteInput optionText={optionRendererPhone} />
            </ReferenceInput>
            <TextInput fullWidth label="Phone number" source="phone" validate={validatePhone} />
            <PasswordEditInput fullWidth autoComplete="new-password" />
            <RepeatPasswordEditInput fullWidth autoComplete="new-password" />
            <RoleEditInput fullWidth />
        </SimpleForm>
    </Edit>;
};