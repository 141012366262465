import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    ImageInput,
    ImageField,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName} from "./validation/validation";
import usersShort from "../../Users/UsersShort";

const optionRendererUser = record => {
    if (record && record.id) {
        return record.name ? `${record.name} #${record.id}` : `#${record.id}`;
    }

    return null;
}

export const CommunityCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <ReferenceInput fullWidth
                            label="Leader"
                            source="leaderId"
                            reference={usersShort.name}
                            allowEmpty={true}
                            filterToQuery={searchText => ({ search: searchText })}>
                <AutocompleteInput optionText={optionRendererUser} />
            </ReferenceInput>
            <ImageInput source="image"
                        label="Image"
                        accept="image/png"
                        maxSize={5242880}
                        placeholder={
                            <p>
                                Drop a picture to upload, or click to select it.<br/>
                                Maximum file size for uploads is 5MB.<br/>
                                Available type(s) of files: png.
                            </p>
                        }>
                <ImageField source="image" title="Image" />
            </ImageInput>
        </SimpleForm>
    </Create>
);