import React from 'react';
import {Chip} from "@material-ui/core";
import { Link } from 'react-router-dom';

const CountChipField = ({ record, countField = 'count', to = {}}) => {
    const color = record[countField] > 0 ? 'secondary' : 'default';
    let clickable = to && record[countField] > 0;
    let disabled = record[countField] === 0;

    return <Chip label={record[countField]}
                 disabled={disabled}
                 clickable={clickable}
                 color={color}
                 component={Link}
                 to={to}/>;
};

export default CountChipField;