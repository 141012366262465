import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {SUPPORT_ADMIN, VIEWER} from "../../../App/Component/Constant/Roles";
import {FeedbacksList} from "./list";
import {FeedbackShow} from "./show";

export default {
    name: "admin/feedbacks",
    list: FeedbacksList,
    show: FeedbackShow,
    permissions: [SUPPORT_ADMIN, VIEWER],
    options: {
        label: 'Support Requests',
        icon: MailOutlineIcon
    },
};