import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    ReferenceField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import InterestsFilter from "./Component/InterestsFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import DeleteButtonMod from "../../../App/Component/Redesign/Button/DeleteButtonMod";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import interestsCategory from "../InterestsCategory";
import interests from "../../../Pages/Attributes/Interests";

export const InterestsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<InterestsFilter />}
          title="List of Interests"
          basePath={`/${interests.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <ReferenceField label="Category" source="categoryId" reference={interestsCategory.name} sortable={false} link="show">
                <TextField source="title" />
            </ReferenceField>
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
                <DeleteButtonMod permissions={[CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);