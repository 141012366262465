import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    required,
    ImageInput,
    ImageField,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateDescription, validateName} from "./validation/validation";

export const NounCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <TextInput fullWidth label="Description" source="description" validate={validateDescription} />
            <ImageInput source="image"
                        label="Image"
                        accept="image/png"
                        validate={[required()]}
                        maxSize={5249495}
                        placeholder={
                            <p>
                                Drop a picture to upload, or click to select it.<br/>
                                Maximum file size for uploads is 5MB.<br/>
                                Available type(s) of files: png.
                            </p>
                        }>
                <ImageField source="image" title="Image" />
            </ImageInput>
        </SimpleForm>
    </Create>
);