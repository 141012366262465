import {maxLength, minLength, required} from "react-admin";

export const validateName = [maxLength(500)];
export const validateText = [required(), maxLength(150)];
export const validateStatus = [required(), minLength(1), maxLength(2)];
export const validateDate = (value) => {
    if (!value) {
        return 'Required';
    }
    let dob = new Date(value);
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18)
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 120)
    if (dob - minDate > 0) {
        return 'Min age is 18 years';
    }
    if (dob - maxDate < 0) {
        return 'Max age is 120 years';
    }
    return undefined;
};