import SpaIcon from "@material-ui/icons/Spa";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {SpiritualPracticesList} from "./list";
import {SpiritualPracticeShow} from "./show";
import {SpiritualPracticeCreate} from "./create";
import {SpiritualPracticeEdit} from "./edit";

export default {
    name: "admin/attributes/spiritual-practices",
    list: SpiritualPracticesList,
    show: SpiritualPracticeShow,
    create: HasPermissionsForAction(SpiritualPracticeCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(SpiritualPracticeEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Spiritual Practices',
        icon: SpaIcon,
    }
};