export const ChallengeGroups = [
    { id: 1, name: 'Question 1' },
    { id: 2, name: 'Question 2' },
    { id: 3, name: 'Question 3' },
    { id: 4, name: 'Question 4' },
    { id: 5, name: 'Question 5' },
];

export const ChallengeTypes = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Image' },
    { id: 3, name: 'Audio' },
];

export const ChallengeStatus = [
    { id: 5, name: 'Pending' },
    { id: 10, name: 'Active' },
    { id: 0, name: 'Deactivated' }
];