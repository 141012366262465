import React, {useCallback} from 'react';
import {Button, downloadCSV, useDataProvider, useNotify} from "react-admin";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import jsonExport from 'jsonexport/dist';
import {
    ConnectionStatus,
    INVITE_TYPE_COMMUNITY,
    INVITE_TYPE_USER,
    InviteTypes,
    UserStatus
} from "../constant/userConst";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import users from "../../Users";

const ExportUserButton = ({ filterValues = {}, permissions = null }) => {
    const access = permissions ? HasPermissions(permissions) : true;

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchUsers = useCallback(async () => {
        const params = {};
        params.filter = filterValues;
        params.sort = {order: 'ASC', field: 'name'};
        params.pagination = {perPage: 15000, page: 1};

        return await dataProvider.getList(`${users.name}/export`, params);
    }, [dataProvider, filterValues]);

    const handleMenuItemClick = () => {
        ExportUsers();
    };

    function ExportUsers() {
        fetchUsers()
            .then(({ data }) => {
                const postsForExport = data.map(user => {
                    const postForExport = {};

                    postForExport.id = user.id;
                    postForExport.name = user.name ? `${user.name}` : "";
                    postForExport.birthday =  user.birthday ?? "";
                    postForExport.country = user.countryName ? `${user.countryName}` : "";
                    postForExport.phone = user.phone ?? '';
                    postForExport.email = user.email ? `${user.email}` : "";
                    postForExport.gender = user.gender ?? "";
                    postForExport.languages = user.languages ? `${user.languages}` : "";
                    postForExport.communities = user.communities ? `${user.communities}` : "";
                    postForExport.relationships = user.relationships ? `${user.relationships}` : "";
                    postForExport.dailyLife = user.dailyLife ? `${user.dailyLife}` : "";
                    postForExport.dealBreakers = user.dealBreakers ? `${user.dealBreakers}` : "";
                    postForExport.connectionStatus = ConnectionStatus.find(el => el.id === user.connectionStatus).name;
                    postForExport.countChats = user.countChats;
                    postForExport.userStatus = UserStatus.find(el => el.id === user.status).name;
                    postForExport.inviteType = user.invitationType ? InviteTypes.find(el => el.id === user.invitationType).name : "";

                    let inviteFrom = "";
                    if (user.invitationType === INVITE_TYPE_USER) inviteFrom = `${user.invitationUser}`;
                    if (user.invitationType === INVITE_TYPE_COMMUNITY) inviteFrom = `${user.invitationCommunity}`;
                    postForExport.inviteFrom = inviteFrom;

                    postForExport.countReferrals = user.countReferrals;

                    return postForExport;
                });

                jsonExport(postsForExport, {
                    rename: [
                        'ID',
                        'Name',
                        'Birthday',
                        'Country',
                        'Phone',
                        'Email',
                        'Gender',
                        'Languages',
                        'Communities',
                        'Relationships',
                        'A bit about me',
                        'Deal breakers',
                        'Connection status',
                        'Count matches',
                        'User status',
                        'Invite type',
                        'Invite from',
                        'Count referrals'
                    ],
                    rowDelimiter: ';',
                }, (err, csv) => {
                    downloadCSV(csv, `users_${Date.now()}`);
                });
            })
            .catch(error => {
                notify('custom.export.fail', 'warning', {});
                console.log(error);
            })
    }

    return access && <Button label="Export" onClick={(event) => {handleMenuItemClick()}}><VerticalAlignBottomIcon/></Button>;
};

export default ExportUserButton;