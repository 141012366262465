import React from 'react';
import {Button, useRefresh, useUpdate} from 'react-admin';
import {USER_STATUS_ACTIVATED, USER_STATUS_BLOCKED} from "../constant/userConst";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import users from "../../Users";

const ActivateButton = ({ record }) => {
    const refresh = useRefresh();

    const [active, { loading }] = useUpdate(
        `${users.name}`,
        (record ? record.id : null) + '/status',
        { status: USER_STATUS_ACTIVATED },
        record,
        {
            onSuccess: () => { refresh() },
        }
    );

    return record && record.status === USER_STATUS_BLOCKED
        ? <Button label="Active" onClick={(e) => {e.stopPropagation(); active();}} disabled={loading}>
              <GroupAddIcon />
          </Button>
        : '';
};

export default ActivateButton;