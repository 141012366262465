import React from 'react';
import {
    Edit,
    SimpleForm,
    NumberInput,
} from 'react-admin';
import SubmitToolbar from "../../App/Component/Redesign/SubmitToolbarMod";
import {validateName, validatePriority} from "./validation/validation";
import NameInput from "./Component/NameInput";

export const LanguageEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <NameInput fullWidth label="Name" source="title" validate={validateName} />
            <NumberInput fullWidth label="Priority" source="priority" validate={validatePriority} />
        </SimpleForm>
    </Edit>;
};