export const USER = 'user';
export const ADMIN = 'admin';
export const SUPPORT_ADMIN = 'support-admin';
export const CONTENT_MANAGER = 'content-manager';
export const VIEWER = 'viewer';

export const RolesList = [
    { id: 'ROLE_USER', name: USER },
    { id: 'ROLE_ADMIN', name: ADMIN },
    { id: 'ROLE_SUPPORT_ADMIN', name: SUPPORT_ADMIN },
    { id: 'ROLE_MANAGER', name: CONTENT_MANAGER },
    { id: 'ROLE_VIEWER', name: VIEWER },
];

export const AdminRolesList = [
    { id: 'ROLE_ADMIN', name: 'Admin' },
    { id: 'ROLE_SUPPORT_ADMIN', name: 'Support admin' },
    { id: 'ROLE_MANAGER', name: 'Content manager' },
    { id: 'ROLE_VIEWER', name: 'Viewer' },
];