import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName} from "./validation/validation";

export const ChatReportTypeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
        </SimpleForm>
    </Create>
);