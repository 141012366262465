import React from 'react';
import sharingsBatches from "./../"
import {BatchesStatuses} from "../constant/batchesConst";
import StatusButton from "./StatusButton";

const ChangeStatusButton = ({permissions, ...props}) => {
    return <StatusButton {...props}
                         addLabel={true}
                         label="Status"
                         resource={sharingsBatches.name}
                         options={BatchesStatuses}
                         successNotify='custom.sharings.status.update.success'
                         permissions = {permissions}/>
};

export default ChangeStatusButton;