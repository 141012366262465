import React from 'react';
import {TextInput} from "react-admin";
import {validateEmail} from "../validation/validation";
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";

const EmailEditInput = ({permissions, ...props}) => {
    if (props.record) {
        const isAdmin = props.record.role === ValueFromConst(AdminRolesList, 'Admin');

        return <TextInput {...props} fullWidth disabled={isAdmin} label="Email" source="email" type="email" validate={validateEmail} />;
    }
};

export default EmailEditInput;
