import {ValueFromConst} from "./ConstGeter";
import inMemoryJWT from "../../Auth/inMemoryJwt";
import { ADMIN, RolesList } from "../Constant/Roles";

export const HasPermissions = (permissions = [], roles = null) => {
    roles = roles ?? inMemoryJWT.getRoles();

    if (permissions.includes(ADMIN) !== true) permissions.push(ADMIN);
    permissions = permissions.map(val => { return ValueFromConst(RolesList, val) });

    return permissions.includes(roles);
}

export const HasPermissionsForAction = (action, permissions = []) => {
    return HasPermissions(permissions) ? action : null;
}