import {Create, SimpleForm, TextInput} from "react-admin";
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import React from "react";
import {validateName} from "./validation/validation";

export const ApproachReportTypeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
        </SimpleForm>
    </Create>
);