import React from 'react';
import {
    Datagrid,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    EmailField,
    ShowButton,
    BooleanField,
    FunctionField,
    TopToolbar,
    useListContext,
    sanitizeListRestProps,
    SimpleShowLayout,
    SelectField,
    ArrayField
} from 'react-admin';
import { cloneElement } from 'react';
import ListMod from "../../../App/Component/Redesign/ListMod";
import UsersFilter from "./Component/UsersFilter";
import ActivateButton from "./Component/ActivateButton";
import DeactivateButton from "./Component/DeactivateButton";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import ExportUserButton from "./Component/ExportUserButton";
import {InviteTypes, UserStatus} from "./constant/userConst";
import SourceEntityField from "./Component/SourceEntityField";
import ChipReferralsField from "./Component/ChipReferralsField";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {ADMIN} from "../../../App/Component/Constant/Roles";
import ChipDeviceTypeField from "./Component/ChipDeviceTypeField";
import BlockButton from "./Component/BlockButton";
import countries from "../../Countries/Countries";
import communities from "../../Communities/Communities";
import languages from "../../../Pages/Languages";
import relationships from "../../Relationships";
import identityGenders from "../../IdentityGenders";
import users from "../Users";

export const UsersList = ({permissions, ...props}) => (
    <ListMod {...props}
            filters={<UsersFilter />}
            title="List of Users"
            basePath={`/${users.name}`}
            actions={<ListActions permissions={permissions} />}
    >
        <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <DateFieldMod label="DoB" source="birthday" />
            <ReferenceField label="Country" source="countryId" reference={countries.name} sortable={false} link="show">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="Phone" render={record => record.phone ? `${record.phoneCode}${record.phone}`: ``} />
            <EmailField label="Email" source="email" />
            <BooleanField label="Connection status" source="connectionStatus" sortable={false} valueLabelTrue="Active" valueLabelFalse="Inactive" />
            <BooleanField label="Premium status" source="premiumStatus" sortable={false} valueLabelTrue="Active" valueLabelFalse="Inactive" />
            <ArrayField label="Device OS" source="devices" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipDeviceTypeField source="type" color="secondary" />
                </SingleFieldList>
            </ArrayField>
            <SelectField label="User status" source="status" sortable={false} choices={UserStatus} />
            <DateFieldMod label="Created At" source="createdAt" showTime />
            <VerticalToolbar {...props} >
                <ShowButton />
                <EditButtonMod permissions={[ADMIN]} />
                <ActivateButton permissions={[ADMIN]} />
                <BlockButton permissions={[ADMIN]} />
                <DeactivateButton permissions={[ADMIN]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <ReferenceArrayField label="Languages" reference={languages.name} source="languages">
            <SingleFieldList linkType={false}>
                <ChipField source="title" color="secondary" />
            </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField label="Gender" source="gender" reference={identityGenders.name} link={false}>
            <TextField source="title" />
        </ReferenceField>
        <ReferenceArrayField label="Relationship status" source="relationships" reference={relationships.name} link={false}>
            <SingleFieldList linkType={false}>
                <ChipField source="title" color="secondary" />
            </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="Communities" source="communities" reference={communities.name}>
            <SingleFieldList linkType={false}>
                <ChipField source="title" color="secondary" />
            </SingleFieldList>
        </ReferenceArrayField>
        <SelectField label="Source of registration" source="invitationType" choices={InviteTypes} />
        <SourceEntityField addLabel={true} label="Source entity" />
        <ChipReferralsField addLabel={true} label="Count referrals" />
        <BooleanField label="Is influencer" source="influencer" valueLabelTrue="Yes" valueLabelFalse="No" />
        <ReferenceArrayField label="Leading communities" source="leadingCommunities" reference={communities.name}>
            <SingleFieldList linkType={false}>
                <ChipField source="title" color="secondary" />
            </SingleFieldList>
        </ReferenceArrayField>
        <DateFieldMod addLabel={true} label="Last Activity At" source="lastOnlineAt" showTime />
        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
    </SimpleShowLayout>
);

const ListActions = ({permissions, ...props}) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {resource, displayedFilters, filterValues, showFilter} = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button'})}
            <ExportUserButton filterValues={filterValues} permissions={[ADMIN]} />
        </TopToolbar>
    );
};