import React from 'react';
import {
    ChipField,
    Datagrid,
    FunctionField,
    ReferenceField,
    RichTextField,
    ShowButton,
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import approachesReports from "../../../Pages/Approaches/Reports";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import UserField from "../../Users/Users/Component/UserField";
import countries from "../../Countries/Countries";
import ReportsFilter from "../Reports/Component/ReportsFilter";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";

export const ApproachesReportsList = ({permissions, ...props}) => (
    <ListMod {...props}
             filters={<ReportsFilter />}
             title="List of Approaches Reports"
             basePath={`/${approachesReports.name}`}
    >
        <ReportsDataGrid />
    </ListMod>
);

const ReportsDataGrid = props => {
    if (!HasPermissions([SUPPORT_ADMIN])) {
        return <Datagrid expand={<PostShow />}>
            <TextField label="ID" source="id" sortBy="id" />
            <ChipField label="Feedback Option" source="option" color="secondary" />
        </Datagrid>
    }

    return <Datagrid expand={<PostShow />}>
        <TextField label="ID" source="id" sortBy="id" />
        <ChipField label="Feedback Option" source="option" color="secondary" />

        <UserField label="Reporting User" source="reporter" sortable={false} />
        <ReferenceField label="Reporting Country" source="reporterCountryId" reference={countries.name} sortable={false} link="show">
            <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Reporting Phone" render={record => record.reporterPhone ? `${record.reporterPhoneCode}${record.reporterPhone}` : ``} sortable={false} />

        <UserField label="Reported User" source="reported" sortable={false} />
        <ReferenceField label="Reported Country" source="reportedCountryId" reference={countries.name} sortable={false} link="show">
            <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Reported Phone" render={record => record.reportedPhone ? `${record.reportedPhoneCode}${record.reportedPhone}` : ``} sortable={false} />
        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />

        <ShowButton />
    </Datagrid>
}

const PostShow = props => (
    <SimpleShowLayout {...props}>
        <RichTextField label="Feedback" source="text"  style={{overflowWrap: 'anywhere'}}/>
    </SimpleShowLayout>
);