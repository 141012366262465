import React from 'react';
import {Toolbar} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

const customToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'inherit',
    }
});

const VerticalToolbar = ({ hasCreate, hasEdit, hasList, hasShow, syncWithLocation, children, ...props }) => {
    const style = customToolbarStyles();

    return (
        <Toolbar className={style.toolbar} {...props}>
            {children}
        </Toolbar>
    );
}

export default VerticalToolbar;