import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const GoalShow = ( {permissions, ...props} ) => (
    <Show title="Goal View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
        </SimpleShowLayout>
    </Show>
);
