import PeopleIcon from "@material-ui/icons/People";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {ADMIN, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {UsersList} from "./list";
import {UserShow} from "./show";
import {UserEdit} from "./edit";

export default {
    name: "admin/users",
    list: UsersList,
    show: UserShow,
    edit: HasPermissionsForAction(UserEdit, [ADMIN]),
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Users',
        icon: PeopleIcon
    },
};