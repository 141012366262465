import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName} from "./validation/validation";

export const SharingReportTypeEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
        </SimpleForm>
    </Edit>;
};