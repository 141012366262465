import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {DealBreakersList} from "./list";
import {DealBreakerShow} from "./show";
import {DealBreakerCreate} from "./create";
import {DealBreakerEdit} from "./edit";

export default {
    name: "admin/attributes/deal-breakers",
    list: DealBreakersList,
    show: DealBreakerShow,
    create: HasPermissionsForAction(DealBreakerCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(DealBreakerEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Deal Breakers',
        icon: ThumbsUpDownIcon,
    }
};