import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    SelectField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import ReportTypesFilter from "./Component/ReportTypesFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {SUPPORT_ADMIN, CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import ReportTypeDeleteButtonMod from "./Component/DeleteButtonMod";
import {StatusTextList} from "../../../App/Component/Constant/Status";
import {ValueFromConst} from "../../../App/Component/Handlers/ConstGeter";
import chatReportTypes from "../../../Pages/Chats/ReportTypes";

export const ChatReportTypesList = ({permissions, ...props}) => (
    <ListMod {...props}
             filterDefaultValues={{status: ValueFromConst(StatusTextList, 'Active')}}
             filters={<ReportTypesFilter />}
             title="List of Chat Report Types"
             basePath={`/${chatReportTypes.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <SelectField label="Status" source="status" sortable={false} choices={StatusTextList} />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
                <ReportTypeDeleteButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);