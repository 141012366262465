import * as React from 'react';
import { Box } from '@material-ui/core';
import {CHAT_TYPE_MYSTERY, ChatType} from "../../Chats/constant/chatConst";
import {NameFromId} from "../../../../App/Component/Handlers/ConstGeter";

const getColorFromStatus = (chatType) => chatType === CHAT_TYPE_MYSTERY ? 'rgba(38,1,82,0.6)' : 'rgba(255,208,0,0.6)';

export const ChatTypeBadge = ({chatType}) => {
    return <Box
        width={10}
        height={10}
        display="inline-block"
        borderRadius={5}
        bgcolor={getColorFromStatus(chatType)}
        component="span"
        title={NameFromId(ChatType, chatType)}
    />
};