import React from 'react';
import {Chip} from "@material-ui/core";
import { Link } from 'react-router-dom';
import {NameFromId} from "../../../../App/Component/Handlers/ConstGeter";
import {DeviceTypes} from "../../../Device/Device/constant/deviceConst";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    chip: {
        margin: 4,
    },
});

const ChipDeviceTypeField = ({ record }) => {
    const classes = useStyles();

    if (!record || !record.id) {
        return '';
    }

    const color = 'secondary';
    const clickable = false;
    const label = NameFromId(DeviceTypes, record.type);

    return <Chip className={classes.chip}
                 label={label}
                 clickable={clickable}
                 color={color}
                 component={Link}
                 to={{}}
    />;
};

export default ChipDeviceTypeField;