import React from 'react';
import {Datagrid, SelectField, ShowButton, TextField,} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import ReportTypeDeleteButtonMod from "./Component/DeleteButtonMod";
import sharingsReportTypes from "../../../Pages/Approaches/ReportTypes";
import {StatusTextList} from "./constant/typesConst";
import {ValueFromConst} from "../../../App/Component/Handlers/ConstGeter";
import ReportTypesFilter from "./Component/ReportTypesFilter";

export const ApproachesReportTypesList = ({permissions, ...props}) => (
    <ListMod {...props}
             filterDefaultValues={{status: ValueFromConst(StatusTextList, 'Active')}}
             filters={<ReportTypesFilter />}
             title="List of Approaches Report Types"
             basePath={`/${sharingsReportTypes.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id"/>
            <TextField label="Name" source="title"/>
            <SelectField label="Status" source="status" sortable={false} choices={StatusTextList}/>
            <VerticalToolbar {...props}>
                <ShowButton/>
                <EditButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]}/>
                <ReportTypeDeleteButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]}/>
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);