import React from 'react';
import {
    Filter,
    SearchInput,
    SelectInput,
    TextInput
} from 'react-admin';
import {CommunityStatus} from "../constant/communityConst";
import QuickFilter from "../../../../App/Component/Redesign/QuickFilter";

const CommunitiesFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
        <SelectInput label="Status" source="status" choices={CommunityStatus} />
        <TextInput label="Code" source="code" />
        <QuickFilter source="withCommunityLeader" label="With community leader" defaultValue={true} />

    </Filter>
);

export default CommunitiesFilter;