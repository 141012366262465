import * as React from 'react';
import { useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Message} from "./Message";

const useStyles = makeStyles({
    root: {
        marginTop: '0.5em',
        display: 'flex',
        flexDirection: 'column'
    },
});

export const MessagesIterator = ({chatOwner, showChatType}) => {
    const classes = useStyles();
    const { data, ids, loaded } = useListContext();
    if (!loaded) return null;

    return (
        <>
            <div className={classes.root}>
                {ids.map((id, index) => (
                    <Message
                        message={data[id]}
                        isLast={index === ids.length - 1}
                        showChatType={showChatType}
                        chatOwner={chatOwner}
                        key={index}
                    />
                ))}
            </div>
        </>
    );
};