import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateText} from "./validation/validation";

export const RevealFeedbackOptionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Text" source="text" validate={validateText} />
        </SimpleForm>
    </Create>
);