import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const LanguageShow = ( {permissions, ...props} ) => (
    <Show title="Language View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <TextField label="Priority" source="priority" />
        </SimpleShowLayout>
    </Show>
);
