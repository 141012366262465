import React from 'react';
import {
    Filter,
    SearchInput,
} from 'react-admin';
import SearchByUserId from "../../../Users/Users/Component/SearchByUserId";

const MatchingStateFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
        <SearchByUserId source="userId" alwaysOn />
    </Filter>
);

export default MatchingStateFilter;