import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import DealBreakersFilter from "./Component/DealBreakersFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import DeleteButtonMod from "../../../App/Component/Redesign/Button/DeleteButtonMod";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import dealBreakers from "../../../Pages/Attributes/DealBreakers";

export const DealBreakersList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<DealBreakersFilter />}
          title="List of Deal Breakers"
          basePath={`/${dealBreakers.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Deal breaker name" source="dealBreakerTitle" />
            <TextField label="Daily life name" source="dailyLifeTitle" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
                <DeleteButtonMod permissions={[CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);