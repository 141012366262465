import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    SelectField,
} from 'react-admin';
import {StatusTextList} from "../../../App/Component/Constant/Status";

export const ChatReportTypeShow = ( {permissions, ...props} ) => (
    <Show title="Chat Report Type View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <SelectField label="Status" source="status" choices={StatusTextList} />
        </SimpleShowLayout>
    </Show>
);
