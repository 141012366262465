import LanguageIcon from "@material-ui/icons/Language";
import {ADMIN, SUPPORT_ADMIN} from "../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../App/Component/Handlers/HasPermissions";
import {LanguagesList} from "./list";
import {LanguageShow} from "./show";
import {LanguageCreate} from "./create";
import {LanguageEdit} from "./edit";

export default {
    name: "admin/languages",
    list: LanguagesList,
    show: LanguageShow,
    create: HasPermissionsForAction(LanguageCreate, [ADMIN]),
    edit: HasPermissionsForAction(LanguageEdit, [ADMIN]),
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Languages',
        icon: LanguageIcon
    },
};