import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';

export const CountryShow = ( {permissions, ...props} ) => (
    <Show title="Country View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Dial code" source="dialCode" />
            <TextField label="Code" source="code" />
            <TextField label="Flag" source="flag" />
        </SimpleShowLayout>
    </Show>
);
