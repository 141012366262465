import React from 'react';
import {Chip} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { stringify } from 'qs';
import users from "../../../Users/Users";

const ChipMembersField = ({ record }) => {
    const color = record.members > 0 ? 'secondary' : 'default';
    let clickable = record.members > 0;

    return <Chip label={record.members}
                 disabled={!clickable}
                 clickable={clickable}
                 color={color}
                 component={Link}
                 to={{
                     pathname: `/${users.name}`,
                     search: stringify({
                         filter: JSON.stringify({
                             community: record.id,
                         }),
                     }),
                 }}/>;
};

export default ChipMembersField;