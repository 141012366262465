import React, {useCallback} from 'react';
import {Button, downloadCSV, useDataProvider, useNotify} from "react-admin";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import jsonExport from 'jsonexport/dist';
import {ReportStatus} from "../constant/reportConst";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import reports from '../../Reports';

const ExportReportButton = ({ filterValues = {}, permissions = null }) => {
    const access = permissions ? HasPermissions(permissions) : true;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchReports = useCallback(async () => {
        const params = {};
        params.filter = filterValues;
        params.sort = {order: 'DESC', field: 'id'};
        params.pagination = {perPage: 5000, page: 1};

        return await dataProvider.getList(`${reports.name}/export`, params);
    }, [dataProvider, filterValues]);

    const handleMenuItemClick = () => {
        ExportReports();
    };

    function ExportReports() {
        fetchReports()
            .then(({ data }) => {
                const postsForExport = data.map(feedback => {
                    const postForExport = {};

                    postForExport.id = feedback.id;
                    postForExport.option = feedback.option;

                    postForExport.reporter = feedback.reporter;
                    postForExport.reporterCountry = feedback.reporterCountry;
                    postForExport.reporterPhone = feedback.reporterPhone;

                    postForExport.reported = feedback.reported;
                    postForExport.reportedCountry = feedback.reportedCountry;
                    postForExport.reportedPhone = feedback.reportedPhone;

                    postForExport.status = ReportStatus.find(el => el.id === feedback.status).name;
                    postForExport.text = feedback.text;
                    postForExport.createdAt = feedback.createdAt;

                    return postForExport;
                });

                jsonExport(postsForExport, {
                    rename: [
                        'ID',
                        'Feedback type',
                        'Feedback Option',
                        'Reporting User',
                        'Reporting Country',
                        'Reporting Phone',
                        'Reported User',
                        'Reported Country',
                        'Reported Phone',
                        'Status',
                        'Feedback',
                        'Created At',
                    ],
                    rowDelimiter: ';',
                }, (err, csv) => {
                    downloadCSV(csv, `feedbacks_${Date.now()}`);
                });
            })
            .catch(error => {
                notify('custom.export.fail', 'warning', {});
                console.log(error);
            })
    }

    return access && <Button label="Export" onClick={(event) => {handleMenuItemClick()}}><VerticalAlignBottomIcon/></Button>;
};

export default ExportReportButton;