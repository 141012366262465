import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import VerticalToolbar from "../../App/Component/Redesign/VerticalToolbar";
import ListMod from "../../App/Component/Redesign/ListMod";
import IdentityGendersFilter from "./Component/IdentityGendersFilter";
import identityGenders from "../../Pages/IdentityGenders";

export const IdentityGendersList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<IdentityGendersFilter />}
          title="List of Identity Genders"
          basePath={`/${identityGenders}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <TextField label="Plural Name" source="pluralTitle" />
            <VerticalToolbar {...props}>
                <ShowButton />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);