import * as React from 'react';
import {EditButton} from 'react-admin';
import {HasPermissions} from "../../Handlers/HasPermissions";

const EditButtonMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    return access && <EditButton {...props} />;
};

export default EditButtonMod;