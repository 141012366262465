import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import MultilineTextField from "../../../App/Component/Redesign/Field/MultilineTextField";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";

export const PublicConfigShow = ( {permissions, ...props} ) => (
    <Show title="Setting View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="showName" />
            <MultilineTextField label="Value" addLabel={true} source="value" />
            <DateFieldMod addLabel={true} label="Updated At" source="updatedAt" showTime />
        </SimpleShowLayout>
    </Show>
);
