import React, {useCallback} from 'react';
import {Button, downloadCSV, useDataProvider, useNotify} from "react-admin";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import jsonExport from 'jsonexport/dist';
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import communities from "../../Communities";

const ExportCommunityButton = ({ filterValues = {}, permissions = null }) => {
    const access = permissions ? HasPermissions(permissions) : true;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const fetchCommunities = useCallback(async () => {
        const params = {};
        params.filter = filterValues;
        params.sort = {order: 'ASC', field: 'id'};
        params.pagination = {perPage: 5000, page: 1};

        return await dataProvider.getList(`${communities.name}/export`, params);
    }, [dataProvider, filterValues]);

    const handleMenuItemClick = () => {
        ExportCommunities();
    };

    function ExportCommunities() {
        fetchCommunities()
            .then(({ data }) => {
                const postsForExport = data.map(community => {
                    const postForExport = {};
                    postForExport.id = community.id;
                    postForExport.name = `${community.title}`;
                    postForExport.members = community.members;
                    postForExport.code = community.code;
                    postForExport.link = `${community.link}`;

                    return postForExport;
                });

                jsonExport(postsForExport, {
                    rename: [
                        'ID',
                        'Name',
                        'Number of users',
                        'Code',
                        'Deep Link',
                    ],
                    rowDelimiter: ';',
                }, (err, csv) => {
                    downloadCSV(csv, `communities_${Date.now()}`);
                });
            })
            .catch(error => {
                notify('custom.export.fail', 'warning', {});
                console.log(error);
            })
    }

    return access && <Button label="Export" onClick={(event) => {handleMenuItemClick()}}><VerticalAlignBottomIcon/></Button>;
};

export default ExportCommunityButton;