import {number, email, maxLength, minLength, required} from "react-admin";

const passwordValidation = (value, allValues) => {
    return value !== allValues.password ? 'Passwords don\'t match' : undefined;
}

export const validateName = [required(), minLength(3), maxLength(100)];
export const validateLastName = [required(), minLength(3), maxLength(100)];
export const validateEmail = [required(), email()];
export const validatePhone =  [minLength(4), maxLength(20), number()];
export const validatePhoneCode = [number()];
export const validatePassword =  [required(), minLength(8), maxLength(64)];
export const validateRepeatPassword =  [required(), minLength(8), maxLength(64), passwordValidation];
export const validateRole =  [required()];

export const validatePasswordEdit =  [minLength(8), maxLength(64)];
export const validateRepeatPasswordEdit =  [minLength(8), maxLength(64), passwordValidation];
