import React from 'react';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    AutocompleteInput,
} from 'react-admin';
import interestsCategory from "../../InterestsCategory";

const InterestsFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
        <ReferenceInput label="Category" source="category" reference={interestsCategory.name} filterToQuery={searchText => ({ title: searchText })}>
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
    </Filter>
);

export default InterestsFilter;