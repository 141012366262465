import React from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    DateInput
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import countries from "../../Countries/Countries";
import communities from "../../../Pages/Communities/Communities";
import EmailInput from "./Component/EmailInput";
import {validateDate} from "../../Sharings/Batches/validation/validation";

export const optionRendererPhone = record => {
    return `${record.name} (${record.dialCode})`
}

export const UserEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput disabled fullWidth label="Name" source="name" />
            <EmailInput />
            <ReferenceInput fullWidth
                            label="Phone code"
                            source="phoneCountryId"
                            reference={countries.name}
                            filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput optionText={optionRendererPhone} />
            </ReferenceInput>
            <TextInput fullWidth label="Phone number" source="phone" />
            <DateInput fullWidth label="DoB" source="birthday" validate={validateDate} />
            <BooleanInput label="Is influencer" source="influencer" />
            <ReferenceArrayInput fullWidth label="Leading communities" source="leadingCommunities" reference={communities.name}>
                <AutocompleteArrayInput optionText="title" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>;
};