import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName, validateDialCode} from "./validation/validation";

export const CountryEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="name" validate={validateName} />
            <TextInput fullWidth label="Dial code" source="dialCode" validate={validateDialCode} />
            <TextInput fullWidth disabled label="Code" source="code" />
            <TextInput fullWidth disabled label="Flag" source="flag" />
        </SimpleForm>
    </Edit>;
};