import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import AdjectivesFilter from "./Component/AdjectivesFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import CountChipField from "../../../App/Component/Redesign/Field/CountChipField";
import adjectives from "../../../Pages/Archetypes/Adjectives";

export const AdjectivesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<AdjectivesFilter />}
          title="List of Adjectives"
          basePath={`/${adjectives.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <CountChipField label="Count users" source="count" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);