import ReportIcon from "@material-ui/icons/Report";
import {ApproachesReportTypesList} from "./list";
import {ApproachReportTypeShow} from "./show";
import {ApproachReportTypeCreate} from "./create";
import {ApproachReportTypeEdit} from "./edit";

export default {
    name: "admin/approaches/report/types",
    list: ApproachesReportTypesList,
    show: ApproachReportTypeShow,
    create: ApproachReportTypeCreate,
    edit: ApproachReportTypeEdit,
    options: {
        label: 'Report Types',
        icon: ReportIcon,
    }
};