import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import EndSessionCommentsFilter from "./Component/EndSessionCommentsFilter";
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import {SUPPORT_ADMIN, CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import DeleteButtonMod from "../../../App/Component/Redesign/Button/DeleteButtonMod";
import endSessionComments from "../../../Pages/Chats/EndSessionComments";

export const EndSessionCommentsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<EndSessionCommentsFilter />}
          title="List of End Session Comments"
          basePath={`/${endSessionComments.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
                <DeleteButtonMod permissions={[SUPPORT_ADMIN, CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);