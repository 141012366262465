import * as React from 'react';
import {DeleteButton,} from 'react-admin';
import {HasPermissions} from "../../Handlers/HasPermissions";

const DeleteButtonMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    return access && <DeleteButton {...props} undoable={false} />;
};

export default DeleteButtonMod;