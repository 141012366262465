const TimeZone = 2; // timeZone Israel

const GetTimeZoneOffset = () => {
    const Offset = IsDST() ? 1 : 0;

    return (TimeZone + Offset) * 60 * 60 * 1000;
}

const GetBrowserTimeZoneOffset = () => {
    const date = new Date();

    return date.getTimezoneOffset() * 60 * 1000;
}

const IsDST = () => {
    let jan = new Date((new Date()).getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date((new Date()).getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(jan, jul) !== (new Date()).getTimezoneOffset()
}

export const DateTimeFormatString = (date) => {
    if (!date) {
        return null;
    }

    if (!(date instanceof Date)) {
        date.replace(/ /g, 'T');
        date = new Date(date);
    }

    if (date instanceof Date) {
        date.setTime(date.getTime() + GetTimeZoneOffset());

        /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
        return date.toISOString();
    }

    return null;
}

export const DateTimeWithOutTimeZone = (date) => {
    let dateWithoutOffset = date;

    if (!(dateWithoutOffset instanceof Date)) {
        dateWithoutOffset = new Date(date);
    }

    dateWithoutOffset.setTime(dateWithoutOffset.getTime() - GetBrowserTimeZoneOffset() - GetTimeZoneOffset());

    /** fix for Safari (Date format must be YYYY-MM-DDTHH:mm:ss) */
    return dateWithoutOffset.toISOString();
}