import React from 'react';
import {TextInput} from 'react-admin';
import {REVEAL_COUNT} from "../../Configs/constant/configConst";
import {validateString, validateInteger} from "../validation/validation";

const ValueInput = (props) => {
    if (props.record.id === REVEAL_COUNT) {
        return <TextInput {...props} validate={validateInteger} />
    }

    return <TextInput {...props} validate={validateString} />;
};

export default ValueInput;