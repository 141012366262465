import React from 'react';
import {FunctionField, ReferenceField} from "react-admin";
import usersShort from "../../UsersShort";
import users from "../../Users";

const UserField = (props) => {
    if (!props.record || !props.source) {
        return null;
    }

    const value = props.source.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, props.record);

    return <ReferenceField {...props} reference={usersShort.name} link={() => `/${users.name}/${value}/show`}>
        <FunctionField render={record => record.name ? `${record.name} #${record.id}` : `#${record.id}`}/>
    </ReferenceField>;
}

export default UserField;