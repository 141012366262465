import React from 'react';
import {
    TextField,
    TabbedShowLayout,
    ReferenceField,
    FunctionField,
    RichTextField,
    ChipField,
    Tab,
    ReferenceManyField,
    ShowController,
    ShowView,
} from 'react-admin';
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import ChangeStatusButton from "./Component/ChangeStatusButton";
import {TYPE_REPORT} from "./constant/reportConst";
import {MessagesIterator} from "../../Chats/Messages/Component/MessagesIterator";
import {HasPermissions} from "../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import UserField from "../../Users/Users/Component/UserField";
import countries from "../../Countries/Countries";
import chatMessages from "../../../Pages/Chats/Messages";

export const ReportShow = ( {permissions, ...props} ) => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <TabbedShowLayout>
                    <Tab label="General">
                        <TextField label="ID" source="id" sortBy="id" />
                        <ChipField label="Feedback Option" source="option" color="secondary" />

                        <UserField addLabel={true} label="Reporting User" source="reporter" />
                        <ReferenceField label="Reporting Country" source="reporterCountryId" reference={countries.name} link="show">
                            <TextField source="name" />
                        </ReferenceField>
                        <FunctionField label="Reporting Phone" render={record => record.reporterPhone ? `${record.reporterPhoneCode}${record.reporterPhone}` : ``} />

                        <UserField addLabel={true} label="Reported User" source="reported" />
                        <ReferenceField label="Reported Country" source="reportedCountryId" reference={countries.name} link="show">
                            <TextField source="name" />
                        </ReferenceField>
                        <FunctionField label="Reported Phone" render={record => record.reportedPhone ? `${record.reportedPhoneCode}${record.reportedPhone}` : ``} />

                        <RichTextField label="Feedback" source="text"  style={{overflowWrap: 'anywhere'}}/>
                        <ChangeStatusButton label="Status" addLabel={true} />
                        <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
                    </Tab>
                    {HasPermissions([SUPPORT_ADMIN])
                    && controllerProps.record
                    && controllerProps.record.type === TYPE_REPORT
                    && <Tab label="Messages" path="messages">
                        <ReferenceManyField source="chatId"
                                            target="chatId"
                                            reference={chatMessages.name}
                                            perPage={50}
                                            sort={{ field: 'createdAt', order: 'DESC' }}
                                            addLabel={false}>
                            <MessagesIterator chatOwner={controllerProps.record.reporter} showChatType />
                        </ReferenceManyField>
                    </Tab>
                    }
                </TabbedShowLayout>
            </ShowView>
        }
    </ShowController>
);
