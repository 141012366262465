import React from 'react';
import {
    TextInput,
    Create,
    SimpleForm,
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateName} from "./validation/validation";
import interestsCategory from "../../../Pages/Attributes/InterestsCategory";

export const InterestCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <ReferenceInput fullWidth label="Category" source="categoryId" reference={interestsCategory.name}>
                <SelectInput optionText="title" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);