import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout, SelectField,
} from 'react-admin';
import {StatusTextList} from "../../../App/Component/Constant/Status";

export const RevealFeedbackOptionShow = ( {permissions, ...props} ) => (
    <Show title="Reveal Feedback Option View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" />
            <SelectField label="Status" source="status" choices={StatusTextList} />
        </SimpleShowLayout>
    </Show>
);
