import React from 'react';
import {ReportStatus} from "../constant/reportConst";
import StatusButton from "../../../../App/Component/Redesign/Button/StatusButton";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../../App/Component/Constant/Roles";
import reports from '../../Reports';

const ChangeStatusButton = ({permissions, ...props}) => {
    return <StatusButton {...props}
                         disabled={!HasPermissions([SUPPORT_ADMIN])}
                         addLabel={true}
                         label="Status"
                         resource={reports.name}
                         options={ReportStatus}
                         successNotify='custom.reports.status.update.success' />
};

export default ChangeStatusButton;