import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {AdjectiveCreate} from "./create";
import {AdjectiveEdit} from "./edit";
import {AdjectivesList} from "./list";
import {AdjectiveShow} from "./show";

export default {
    name: "admin/archetypes/adjectives",
    list: AdjectivesList,
    show: AdjectiveShow,
    create: HasPermissionsForAction(AdjectiveCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(AdjectiveEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Adjectives',
        icon: FormatIndentIncreaseIcon,
    },
};
