import React from 'react';
import {
    Datagrid,
    TextField,
    SelectField,
    ShowButton
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import {ChallengeGroups, ChallengeTypes} from "./constant/challengeConst";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import ChallengesFilter from "./Component/ChallengesFilter";
import ChangeStatusButton from "./Component/ChangeStatusButton";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import challenges from "../../../Pages/Challenges/Challenges";

export const ChallengesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<ChallengesFilter />}
          title="List of Challenges"
          basePath={`/${challenges.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Text" source="text" sortable={false} />
            <SelectField label="Type" source="type" choices={ChallengeTypes} />
            <SelectField label="Group" source="group" choices={ChallengeGroups} />
            <ChangeStatusButton label="Status" />
            <VerticalToolbar {...props} >
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);