import FormatIndentDecreaseIcon from "@material-ui/icons/FormatIndentDecrease";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {CONTENT_MANAGER, SUPPORT_ADMIN} from "../../../App/Component/Constant/Roles";
import {NounCreate} from "./create";
import {NounEdit} from "./edit";
import {NounsList} from "./list";
import {NounShow} from "./show";

export default {
    name: "admin/archetypes/nouns",
    list: NounsList,
    show: NounShow,
    create: HasPermissionsForAction(NounCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(NounEdit, [CONTENT_MANAGER]),
    permissions: [SUPPORT_ADMIN, CONTENT_MANAGER],
    options: {
        label: 'Nouns',
        icon: FormatIndentDecreaseIcon,
    }
};
