import React from 'react';
import {Chip} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { stringify } from 'qs';
import {INVITE_TYPE_USER} from "../constant/userConst";
import users from "../../../Users/Users";

const ChipReferralsField = ({ record }) => {
    const color = record.countReferrals > 0 ? 'secondary' : 'default';
    let clickable = false;
    let to = {
        pathname: `/${users.name}`
    };

    if (record.countReferrals  > 0 && record.referralToken) {
        clickable = true;
        to.search = stringify({
            filter: JSON.stringify({
                invitationType: INVITE_TYPE_USER,
                invitationToken: record.referralToken
            }),
        });
    }

    return <Chip label={`${record.countReferrals}`}
                 disabled={!clickable}
                 clickable={clickable}
                 color={color}
                 component={Link}
                 to={to} />;
};

export default ChipReferralsField;