import React from 'react';
import { List } from 'react-admin';
import PaginationMod from "./PaginationMod";

export const ListMod = (props) => {
    const sort = props.sort ? props.sort : { field: 'id', order: 'DESC' };

    return <List pagination={<PaginationMod rowsPerPageOptions={[10, 25, 50, 100]} />}
                 perPage={10}
                 sort={sort}
                 bulkActionButtons={false}
                 exporter={false}
                 {...props}
    />;
}

export default ListMod;
