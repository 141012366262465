import {BatchesList} from "./list";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import {BatchCreate} from "./create";
import {Archive} from "@material-ui/icons";

export default {
    name: "admin/sharings/batches",
    list: BatchesList,
    create: HasPermissionsForAction(BatchCreate, [CONTENT_MANAGER]),
    options: {
        label: 'Batches',
        icon: Archive,
    }
};