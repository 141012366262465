import React, {cloneElement} from "react";
import ListMod from "../../../App/Component/Redesign/ListMod";
import GridList from "./card/GridList";
import MatchingStateFilter from "./card/MatchingStateFilter";
import PaginationMod from "../../../App/Component/Redesign/PaginationMod";
import {sanitizeListRestProps, TopToolbar, useListContext} from "react-admin";
import ExportMatchingButton from "../Component/ExportMatchingButton";
import matching from "../Matching";

export const MatchingStatistic = ({permissions, ...props}) => {
    return <ListMod {...props}
        filters={<MatchingStateFilter />}
        pagination={<PaginationMod rowsPerPageOptions={[8, 16, 32, 64, 128]} />}
        perPage={8}
        title="Matching State"
        basePath={`/${matching.name}`}
        component="div"
        actions={<ListActions permissions={permissions} />}
    >
        <GridList />
    </ListMod>
};

const ListActions = ({permissions, ...props}) => {
    const {className, exporter, filters, maxResults, ...rest} = props;
    const {resource, displayedFilters, filterValues, showFilter} = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button'})}
            <ExportMatchingButton filterValues={filterValues} />
        </TopToolbar>
    );
};

export default MatchingStatistic;
