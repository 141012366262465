import React from 'react';
import {SaveButton, Toolbar} from "react-admin";
import BackButton from "./Button/BackButton";

const SubmitToolbar = props => (
    <Toolbar {...props} >
        {props.saveButton ? props.saveButton : <SaveButton />}
        <BackButton/>
    </Toolbar>
);

export default SubmitToolbar;
