import React from 'react';
import DeleteButtonMod from "../../../../App/Component/Redesign/Button/DeleteButtonMod";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";
import {StatusTextList} from "../../../../App/Component/Constant/Status";

const ReportTypeDeleteButtonMod = (props) => {
    const access = props.permissions ? HasPermissions(props.permissions) : true;

    return access && props.record && props.record.status !== ValueFromConst(StatusTextList, 'Deleted') ?
        <DeleteButtonMod { ...props } /> : '';
};

export default ReportTypeDeleteButtonMod;