import React from 'react';
import {
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin';
import {ChallengeGroups, ChallengeStatus, ChallengeTypes} from "../constant/challengeConst";

const ChallengesFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder="Search by Text" source="search" alwaysOn />
        <SelectInput label="Status" source="status" choices={ChallengeStatus} />
        <SelectInput label="Group" source="group" choices={ChallengeGroups} />
        <SelectInput label="Type" source="type" choices={ChallengeTypes} />
    </Filter>
);

export default ChallengesFilter;