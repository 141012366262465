import React from 'react';
import {
    ReferenceInput,
    Filter,
    SearchInput,
    SelectInput,
    AutocompleteInput,
    TextInput
} from 'react-admin';
import {ReportStatus} from '../constant/reportConst';
import DateTimeInputMod from "../../../../App/Component/Redesign/Input/DateTimeInputMod";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../../App/Component/Constant/Roles";
import SearchByUserId from "../../../Users/Users/Component/SearchByUserId";
import countries from "../../../Countries/Countries";
import chatReportTypes from "../../../../Pages/Chats/ReportTypes";

const ReportsFilter = ({permissions, ...props}) => (
    <Filter {...props}>
        {HasPermissions([SUPPORT_ADMIN]) &&
            <SearchInput placeholder="Search by Users" source="search" alwaysOn/>
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <SearchByUserId source="userId" alwaysOn />
        }
        <SelectInput label="Status" source="status" choices={ReportStatus} />
        <ReferenceInput label="Chosen report option" source="reportOption" reference={chatReportTypes.name}>
            <SelectInput optionText="title" />
        </ReferenceInput>

        {HasPermissions([SUPPORT_ADMIN]) &&
            <ReferenceInput label="Reporting user country" source="reportingCountryId" reference={countries.name}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <TextInput label="Reporting user phone" source="reportingPhone" />
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <TextInput label="Reporting user name" source="reportingName" />
        }

        {HasPermissions([SUPPORT_ADMIN]) &&
            <ReferenceInput label="Reported user country" source="reportedCountryId" reference={countries.name}
                            filterToQuery={searchText => ({name: searchText})}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <TextInput label="Reported user phone" source="reportedPhone" />
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <TextInput label="Reported user name" source="reportedName" />
        }

        <DateTimeInputMod label="Date From" source="dateFrom" />
        <DateTimeInputMod label="Date To" source="dateTo" />
    </Filter>
);

export default ReportsFilter;