import React from 'react';
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";
import {AdminStatus} from "../constant/adminConst";
import {AdminRolesList} from "../../../../App/Component/Constant/Roles";
import DeleteButtonMod from "../../../../App/Component/Redesign/Button/DeleteButtonMod";

const DeactivateButton = ({ ...props }) => {
    if (!props.record || props.record.role === ValueFromConst(AdminRolesList, 'Admin')) {
        return null;
    }

    return props.record && props.record.status !== ValueFromConst(AdminStatus, 'Deactivated') ?
        <DeleteButtonMod { ...props } label="Deactivate" /> : '';
};

export default DeactivateButton;