import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {ChallengesList} from "./list";
import {ChallengeShow} from "./show";
import {ChallengeCreate} from "./create";
import {ChallengeEdit} from "./edit";

export default {
    name: "admin/challenges",
    list: ChallengesList,
    show: ChallengeShow,
    create: HasPermissionsForAction(ChallengeCreate, [CONTENT_MANAGER]),
    edit: HasPermissionsForAction(ChallengeEdit, [CONTENT_MANAGER]),
    options: {
        label: 'Challenges',
        icon: HelpOutlineIcon,
    }
};