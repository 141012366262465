import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "./Dashboard";

export default {
    name: "admin/statistics/basic",
    list: Dashboard,
    options: {
        label: 'Dashboards',
        icon: DashboardIcon
    },
};