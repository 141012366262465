import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import { validateName } from "./validation/validation";
import lifePurposesCategory from "../LifePurposesCategory";

export const LifePurposeEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <NumberInput disabled fullWidth label="ID" source="id" />
            <TextInput fullWidth label="Name" source="title" validate={validateName} />
            <ReferenceInput fullWidth disabled label="Category" source="categoryId" reference={lifePurposesCategory.name}>
                <SelectInput optionText="title" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>;
};