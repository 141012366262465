import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {CONTENT_MANAGER} from "../../App/Component/Constant/Roles";

export default {
    permissions: [CONTENT_MANAGER],
    options: {
        permissions: [CONTENT_MANAGER],
        label: 'Sharings',
        icon: HelpOutlineIcon,
    },
};