import ReportIcon from "@material-ui/icons/Report";
import {SharingsReportTypesList} from "./list";
import {SharingsReportTypeShow} from "./show";
import {SharingReportTypeCreate} from "./create";
import {SharingReportTypeEdit} from "./edit";

export default {
    name: "admin/sharings/report/types",
    list: SharingsReportTypesList,
    show: SharingsReportTypeShow,
    create: SharingReportTypeCreate,
    edit: SharingReportTypeEdit,
    options: {
        label: 'Report Types',
        icon: ReportIcon,
    }
};