import React from 'react';
import { Admin } from 'react-admin';
import dataProvider from './App/Api/api';
import authProvider from './App/Auth/authProvider';
import theme from "./App/Style/theme";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';

import ResourceMod from "./App/Component/Redesign/ResourceMod";
import CustomLayout from './App/Component/Layouts/CustomLayout';
import LoginForm from './App/Component/Layouts/LoginForm';
import CustomMenu from "./App/Component/Layouts/CustomMenu";

import dashboard from "./Pages/Statistics/Basic";
import matching from "./Pages/Statistics/Matching";
import usersShort from './Pages/Users/UsersShort';
import users from './Pages/Users/Users';
import admins from './Pages/Users/Admins';
import challenges from './Pages/Challenges/Challenges';
import communities from './Pages/Communities/Communities';
import reports from './Pages/Reports/Reports';
import feedbacks from "./Pages/Feedbacks/Feedbacks";
import dealBreakers from "./Pages/Attributes/DealBreakers";
import interests from "./Pages/Attributes/Interests";
import interestsCategory from "./Pages/Attributes/InterestsCategory";
import lifePurposesCategory from "./Pages/Attributes/LifePurposesCategory";
import lifePurposes from "./Pages/Attributes/LifePurposes";
import spiritualPractices from "./Pages/Attributes/SpiritualPractices";
import nouns from "./Pages/Archetypes/Nouns";
import adjectives from "./Pages/Archetypes/Adjectives";
import countries from "./Pages/Countries/Countries";
import cities from "./Pages/Cities";
import languages from "./Pages/Languages";
import identityGenders from "./Pages/IdentityGenders";
import goals from "./Pages/Goals";
import relationships from "./Pages/Relationships";
import chats from "./Pages/Chats/Chats";
import chatMessages from "./Pages/Chats/Messages";
import chatReportTypes from "./Pages/Chats/ReportTypes";
import endSessionComments from "./Pages/Chats/EndSessionComments";
import revealFeedbacks from "./Pages/Reveals/Feedbacks";
import revealFeedbackOptions from "./Pages/Reveals/FeedbackOptions";
import publicConfigs from "./Pages/Configs/PublicConfigs";
import configs from "./Pages/Configs/Configs";
import sharingsQuestions from "./Pages/Sharings/Questions";
import sharingsBatches from "./Pages/Sharings/Batches";
import sharingsReportTypes from "./Pages/Sharings/ReportTypes";
import approachesReports from "./Pages/Approaches/Reports";
import approachesReportTypes from "./Pages/Approaches/ReportTypes";
import adminsShort from "./Pages/Users/AdminsShort";


const i18nProvider = polyglotI18nProvider(locale => (englishMessages), 'en');

const App = () => (
    <Admin loginPage={LoginForm}
           theme={theme}
           menu={CustomMenu}
           dashboard={dashboard.list}
           dataProvider={dataProvider}
           authProvider={authProvider}
           layout={CustomLayout}
           i18nProvider={i18nProvider}>
        {() => [
            <ResourceMod {...dashboard} />,
            <ResourceMod {...matching} />,
            <ResourceMod {...usersShort} />,
            <ResourceMod {...users} />,
            <ResourceMod {...admins} />,
            <ResourceMod {...adminsShort} />,
            <ResourceMod {...challenges} />,
            <ResourceMod {...communities} />,
            <ResourceMod {...reports} />,
            <ResourceMod {...feedbacks} />,
            <ResourceMod {...interests} />,
            <ResourceMod {...interestsCategory} />,
            <ResourceMod {...dealBreakers} />,
            <ResourceMod {...spiritualPractices} />,
            <ResourceMod {...lifePurposes} />,
            <ResourceMod {...lifePurposesCategory} />,
            <ResourceMod {...nouns} />,
            <ResourceMod {...adjectives} />,
            <ResourceMod {...countries} />,
            <ResourceMod {...cities} />,
            <ResourceMod {...languages} />,
            <ResourceMod {...identityGenders} />,
            <ResourceMod {...goals} />,
            <ResourceMod {...relationships} />,
            <ResourceMod {...chats} />,
            <ResourceMod {...chatMessages} />,
            <ResourceMod {...chatReportTypes} />,
            <ResourceMod {...endSessionComments} />,
            <ResourceMod {...revealFeedbacks} />,
            <ResourceMod {...revealFeedbackOptions} />,
            <ResourceMod {...publicConfigs} />,
            <ResourceMod {...configs} />,
            <ResourceMod {...sharingsQuestions} />,
            <ResourceMod {...sharingsBatches} />,
            <ResourceMod {...sharingsReportTypes} />,
            <ResourceMod {...approachesReports} />,
            <ResourceMod {...approachesReportTypes} />,
        ]}
    </Admin>
);

export default App;