import React from 'react';
import {FunctionField} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    span: {
        display: 'block',
        minHeight: 20,
    },
}));

const MultilineTextField = ({source, ...props}) => {
    const classes = useStyles();

    return <FunctionField {...props} render={record => record[source].split('\n').map((paragraph, index) => (
        <span className={classes.span} key={index}>{paragraph}</span>
    ))}/>
};

export default MultilineTextField;