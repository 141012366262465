import React from 'react';
import {AutocompleteInput, Filter, ReferenceInput, SearchInput,} from 'react-admin';
import countries from "../../Countries/Countries";

const CitiesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Country" source="countryId" reference={countries.name} filterToQuery={searchText => ({ name: searchText })} alwaysOn>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SearchInput placeholder="Search by Name" source="search" alwaysOn />
    </Filter>
);

export default CitiesFilter;