import React, { Fragment } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import {HasPermissions} from "../Handlers/HasPermissions";
import {SvgIcon} from "@material-ui/core";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        paddingLeft: 15,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
}));

const SubMenu = ({handleToggle, isOpen, options, children, permissions = null}) => {
    const classes = useStyles();
    const sidebarIsOpen = useSelector(state => state.admin.ui.sidebarOpen);

    const access = permissions ? HasPermissions(permissions) : true;
    if (!access) {
        return null;
    }

    const header = (
        <MenuItem button onClick={handleToggle}>
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandMore /> : <SvgIcon component={options.icon} />}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {options.label}
            </Typography>
        </MenuItem>
    );

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={options.label} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                      component="div"
                      disablePadding
                      className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
                >
                    {children}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;