import React from 'react';
import {ChipField, FunctionField, ReferenceField, RichTextField, Show, SimpleShowLayout, TextField,} from 'react-admin';
import UserField from "../../Users/Users/Component/UserField";
import countries from "../../Countries/Countries";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";

export const ApproachReportShow = ( {permissions, ...props} ) => (
    <Show title="Approach Report Type View" {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <ChipField label="Feedback Option" source="option" color="secondary" />

            <RichTextField label="Feedback" source="text"  style={{overflowWrap: 'anywhere'}}/>
            <RichTextField label="Approach message" source="approachText"  style={{overflowWrap: 'anywhere'}}/>

            <UserField addLabel={true} label="Reporting User" source="reporter" />
            <ReferenceField label="Reporting Country" source="reporterCountryId" reference={countries.name} link="show">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="Reporting Phone" render={record => record.reporterPhone ? `${record.reporterPhoneCode}${record.reporterPhone}` : ``} />

            <UserField addLabel={true} label="Reported User" source="reported" />
            <ReferenceField label="Reported Country" source="reportedCountryId" reference={countries.name} link="show">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField label="Reported Phone" render={record => record.reportedPhone ? `${record.reportedPhoneCode}${record.reportedPhone}` : ``} />
            <DateFieldMod addLabel={true} label="Created At" source="createdAt" showTime />
        </SimpleShowLayout>
    </Show>
);
