import React from 'react';
import {Button, useRefresh, useUpdate} from 'react-admin';
import {ValueFromConst} from "../../../../App/Component/Handlers/ConstGeter";
import {CommunityStatus} from "../constant/communityConst.js";
import ReplayIcon from '@material-ui/icons/Replay';
import communities from "../../Communities";

const ActivateButton = ({ record }) => {
    const refresh = useRefresh();

    const [active, { loading }] = useUpdate(
        `${communities.name}`,
        (record ? record.id : null) + '/status',
        { status: ValueFromConst(CommunityStatus, 'Active') },
        record,
        {
            onSuccess: () => { refresh() },
        }
    );

    return record && record.status === ValueFromConst(CommunityStatus, 'Deactivate')
        ? <Button label="Active" onClick={(e) => {e.stopPropagation(); active();}} disabled={loading}><ReplayIcon /></Button> : '';
};

export default ActivateButton;