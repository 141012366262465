import LocationOnIcon from "@material-ui/icons/LocationOn";
import {CitiesList} from "./list";
import {CityShow} from "./show";
import {SUPPORT_ADMIN} from "../../App/Component/Constant/Roles";

export default {
    name: "admin/cities",
    list: CitiesList,
    show: CityShow,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Cities',
        icon: LocationOnIcon
    }
};