import React from 'react';
import {
    Filter,
    SearchInput,
} from 'react-admin';
import DateTimeInputMod from "../../../../App/Component/Redesign/Input/DateTimeInputMod";
import {HasPermissions} from "../../../../App/Component/Handlers/HasPermissions";
import {SUPPORT_ADMIN} from "../../../../App/Component/Constant/Roles";
import SearchByUserId from "../../../Users/Users/Component/SearchByUserId";

const RevealFeedbacksFilter = ({permissions, ...props}) => (
    <Filter {...props}>
        {HasPermissions([SUPPORT_ADMIN]) &&
            <SearchInput placeholder="Search by User" source="search" alwaysOn/>
        }
        {HasPermissions([SUPPORT_ADMIN]) &&
            <SearchByUserId source="userId" alwaysOn />
        }
        <DateTimeInputMod label="Date From" source="dateFrom" />
        <DateTimeInputMod label="Date To" source="dateTo" />
    </Filter>
);

export default RevealFeedbacksFilter;