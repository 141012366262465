import FacebookIcon from "@material-ui/icons/Facebook";
import {SUPPORT_ADMIN, VIEWER} from "../../../App/Component/Constant/Roles";
import {HasPermissionsForAction} from "../../../App/Component/Handlers/HasPermissions";
import {CommunitiesList} from "./list";
import {CommunityShow} from "./show";
import {CommunityCreate} from "./create";
import {CommunityEdit} from "./edit";

export default {
    name: "admin/communities",
    list: CommunitiesList,
    show: CommunityShow,
    create: HasPermissionsForAction(CommunityCreate, [SUPPORT_ADMIN]),
    edit: HasPermissionsForAction(CommunityEdit, [SUPPORT_ADMIN]),
    permissions: [SUPPORT_ADMIN, VIEWER],
    options: {
        label: 'Communities',
        icon: FacebookIcon
    },
};