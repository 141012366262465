import React from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import VerticalToolbar from "../../../App/Component/Redesign/VerticalToolbar";
import SpiritualPracticesFilter from "./Component/SpiritualPracticesFilter";
import EditButtonMod from "../../../App/Component/Redesign/Button/EditButtonMod";
import DeleteButtonMod from "../../../App/Component/Redesign/Button/DeleteButtonMod";
import {CONTENT_MANAGER} from "../../../App/Component/Constant/Roles";
import spiritualPractices from "../../../Pages/Attributes/SpiritualPractices";

export const SpiritualPracticesList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<SpiritualPracticesFilter />}
          title="List of Spiritual Practices"
          basePath={`/${spiritualPractices.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <TextField label="Name" source="title" />
            <VerticalToolbar {...props}>
                <ShowButton />
                <EditButtonMod permissions={[CONTENT_MANAGER]} />
                <DeleteButtonMod permissions={[CONTENT_MANAGER]} />
            </VerticalToolbar>
        </Datagrid>
    </ListMod>
);