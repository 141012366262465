import {SUPPORT_ADMIN} from "../../App/Component/Constant/Roles";
import {RelationshipsList} from "./list";
import {RelationshipShow} from "./show";
import PeopleIcon from "@material-ui/icons/People";

export default {
    name: "admin/relationships",
    list: RelationshipsList,
    show: RelationshipShow,
    permissions: [SUPPORT_ADMIN],
    options: {
        label: 'Relationships',
        icon: PeopleIcon
    },
};