import * as React from 'react';
import {
    ReferenceField,
    FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {ChatTypeBadge} from "./ChatTypeBadge";
import {MessageBody} from "./MessageBody";
import DateFieldMod from "../../../../App/Component/Redesign/Field/DateFieldMod";
import users from "../../../Users/Users";


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
        width: '70%',
        maxWidth: '900px'
    },
    alignRight: {
        alignSelf: 'flex-end',
        textAlign: 'right'
    },
    metadata: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    content: {
        backgroundColor: '#fafafa',
        padding: '0 1em',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'stretch',
        marginBottom: theme.spacing(1),
    },
    text: {
        flex: 1,
        wordWrap: 'break-word',
        width: '70%',
    },
}));

export const Message = ({message, chatOwner, isLast, showChatType}) => {
    const classes = useStyles();

    return (
        <div className={!chatOwner || chatOwner === message.senderId ? `${classes.root}` : `${classes.root} ${classes.alignRight}`}>
            <div className={classes.metadata}>
                <ReferenceField
                    record={message}
                    source="senderId"
                    reference={users.name}
                    link="show"
                >

                    <FunctionField render={record => record.name ? `${record.name} #${record.id}` : `#${record.id}`} variant="body1" />
                </ReferenceField>{' '}
                <DateFieldMod source="createdAt" record={message} variant="body1" showTime/>{' '}
                {showChatType && <ChatTypeBadge chatType={message.chatType} />}
            </div>
            <div className={classes.content}>
                <div className={classes.text}>
                    <MessageBody message={message} />
                </div>
            </div>
        </div>
    );
};