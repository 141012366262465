import React from 'react';
import { Pagination, useListContext } from 'react-admin';

export const PaginationMod = (props) => {
    const { setPage, setPerPage } = useListContext();

    const handleChangePage = (event, newPage) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setPage(newPage+1);
    };

    const handlePerPageChange = (event) => {
        setPerPage(event.target.value);
        setPage(1);
    };

    return <Pagination onChangeRowsPerPage={handlePerPageChange} onChangePage={handleChangePage} {...props} />;
}

export default PaginationMod;
