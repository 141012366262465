import React from 'react';
import {Edit, SelectInput, SimpleForm, TextInput,} from 'react-admin';
import SubmitToolbar from "../../../App/Component/Redesign/SubmitToolbarMod";
import {validateText} from "./validation/validation";
import {QuestionCategories} from "./constant/questionConst";

export const QuestionEdit = (props) => {
    return <Edit {...props} mutationMode="pessimistic">
        <SimpleForm redirect="list" variant="outlined" toolbar={<SubmitToolbar />}>
            <TextInput disabled fullWidth label="ID" source="id" />
            <TextInput multiline fullWidth label="Text" source="text" validate={validateText} />
            <SelectInput disabled fullWidth label="Category" source="category" choices={QuestionCategories} />
        </SimpleForm>
    </Edit>;
};