import React, {useCallback} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {useDataProvider, useNotify} from "react-admin";

const StatusButton = ({ record, resource, options, disabled = false, successNotify = 'custom.status.update.success'}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(record.status);
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const updateStatus = useCallback(async (status) => {
        return await dataProvider.update(
            resource,
            {
                id: record.id + '/status',
                data: { status: status },
                previousData: record
            }
        );
    }, [dataProvider, resource, record]);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);

        updateStatus(index)
            .then(({ data }) => {
                setSelectedIndex(data.status);
                notify(successNotify, 'info', {});
            })
            .catch(error => {
                console.log(error);
                setSelectedIndex(record.status);
                if (error?.body?.errors?.message) {
                    notify(error.body.errors.message, 'warning');
                }
            })
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="flex-start">
            <Grid item xs={12}>
                <ButtonGroup disabled={disabled} variant="contained" color="secondary" ref={anchorRef} aria-label="split button" style={{height: 32}}>
                    <Button style={{width: 120, lineHeight: 1, textTransform: 'capitalize'}}>
                        {options.find(el => el.id === selectedIndex).name}
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="Select status"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        style={{minWidth: 32, width: 32}}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 100}}>
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option) => (
                                            <MenuItem
                                                key={option.id}
                                                selected={option.id === selectedIndex}
                                                onClick={(event) => {handleMenuItemClick(event, option.id)}}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

export default StatusButton;
