import React from 'react';
import {
    Datagrid,
    SelectField,
    TextField,
} from 'react-admin';
import ListMod from "../../../App/Component/Redesign/ListMod";
import ChatsFilter from "./Component/ChatsFilter";
import {ChatStatus, ChatType} from "./constant/chatConst";
import DateFieldMod from "../../../App/Component/Redesign/Field/DateFieldMod";
import UserField from "../../Users/Users/Component/UserField";
import chatsList from "../../../Pages/Chats/Chats";

export const ChatsList = ({permissions, ...props}) => (
    <ListMod {...props}
          filters={<ChatsFilter />}
          title="List of Chats"
          basePath={`/${chatsList.name}`}
    >
        <Datagrid>
            <TextField label="ID" source="id" />
            <UserField label="User 1" source="ownerId" sortable={false} />
            <UserField label="User 2" source="userId" sortable={false} />
            <SelectField label="Type" source="type" choices={ChatType} sortable={false} />
            <SelectField label="Status" source="status" choices={ChatStatus} sortable={false} />
            <DateFieldMod label="Created At" source="createdAt" showTime />
            <DateFieldMod label="Last Activity At" source="lastMessageAt" showTime />
        </Datagrid>
    </ListMod>
);